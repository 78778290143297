import DynamicTable from "components/DynamicTable";
import { CardBrandReverse } from "constants/cards";
import moment from "moment";
import { useMemo } from "react";
import { ToastContainer } from "react-toastify";
import "../Transactions/Transaction.scss";
import { TransactionDataType } from "../Transactions/Transactions";

const TransactionsSummaryModal = (props: any) => {
  //   const [transactionsData, setTransactionsData] = useState([]);
  const { transactionsData } = props;
  const transactionsDatas = transactionsData?.transactions;
  
  const transactionTableData = useMemo<TransactionDataType[]>(() => {
    try {
      return transactionsDatas?.map((txn: any) => {
        console.log("txn43", txn);
        return {
          id: txn?.id,

          createdAt: moment(txn?.createdAt).format("YYYY-MM-DD HH:mm"),

          txnType: txn?.type,

          cardDetails: `${CardBrandReverse?.[txn?.card?.brand] || ""}****${
            txn?.card?.lastFour || ""
          }`,
          status: `${txn?.transactionStatus}`,
          amount: `$${parseFloat(txn?.amount).toFixed(2)}`,
          remarks: txn?.remarks,
          isVerified: `${txn?.isVerified}`,
        };
      });
    } catch (err) {
      console.log(err);

      return [];
    }
  }, [transactionsDatas]);

  return (
    <>
      <ToastContainer />

      <DynamicTable<TransactionDataType>
        title="Transactions Summary Detail"
        td={transactionTableData}
        th={[
          { accessor: "createdAt", h: "Created At", isSortable: true },

          { accessor: "amount", h: "Amount", isSortable: true },
          { accessor: "status", h: "Transaction Status", isSortable: true },
          { accessor: "isVerified", h: "isVerified", isSortable: true },
          { accessor: "remarks", h: "Remarks", isSortable: true },
          { accessor: "txnType", h: "Transaction Type", isSortable: true },
        ]}
      />
    </>
  );
};

export default TransactionsSummaryModal;
