import DynamicTable from "components/DynamicTable";
import { useFilter } from "components/DynamicTable/FilterOptions";
import useGetAccessToken from "hooks/useGetAccessToken";
import moment from "moment";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { ToastContainer } from "react-toastify";
import { getContacts } from "api/clientMessages";
import { TransactionDataType } from "../AdminPortal/Transactions/Transactions";

const ClientMessage = () => {
  const { filterOptions } = useFilter({ limit: 10, order: "desc" });
  const token = useGetAccessToken();
  const { data: allContacts, isLoading, refetch } = useQuery(
    ["all contacts", token, filterOptions],
    () => token && getContacts(token, filterOptions),
    {
      // keepPreviousData: true,
    },
  );

  const contactsTableData = useMemo<TransactionDataType[]>(() => {
    try {
      return allContacts?.rows.map((txn: any) => {
        return {
          id: txn?.id,
          createdAt: moment(txn?.createdAt).format("YYYY-MM-DD HH:mm"),
          email: txn?.email,
          type: txn?.type,
          message: txn?.message,
        };
      });
    } catch (err) {
      console.log(err);

      return [];
    }
  }, [allContacts]);

  return (
    <>
      <ToastContainer />

      <DynamicTable<TransactionDataType>
        title="Client Messages"
        td={contactsTableData}
        th={[
          { accessor: "createdAt", h: "Created At", isSortable: true },
          { accessor: "email", h: "Email", isSortable: true },
          { accessor: "message", h: "Message", isSortable: false },
          { accessor: "type", h: "Subject", isSortable: false },
        ]}
        totalPages={allContacts?.lastPage}
        totalResults={allContacts?.totalCount}
        isLoading={isLoading}
        showMore={""}
        showFilter
        // filterList={[{ field: "createdAt", type: "date", label: "Created-At" }]}
      />
    </>
  );
};

export default ClientMessage;
