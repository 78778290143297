import { useAuth0 } from "@auth0/auth0-react";
import { logoutloader } from "actions/logoutloader";
import { toggleSidebarAction } from "actions/utils";
import backToAdmin from "assets/img/backToAdmin.svg";
import hamburgerMenuIcon from "assets/img/menu_icon.png";
import supportIcon from "assets/img/support-icon1.svg";
import AppDropdown from "common/AppDropdown";
import { LogoutIcon, UserCircleIcon } from "common/AppIcons";
import Sidebar from "components/Sidebar/Sidebar";
import Support from "components/Support/Support";
import * as routes from "constants/routes";
import { usePermission } from "Context/AdminContext";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "store";
import { INavbarItems } from "./INavbarItems";
import { navbarItems } from "./navbar.config";
import "./navbar.scss";

type ModalProps = {
  supportModalVisibility: boolean;
  setSupportModalVisibility: Function;
};

export const SupportModal = ({ supportModalVisibility, setSupportModalVisibility }: ModalProps) => {
  return (
    <Modal
      id="supportid"
      show={supportModalVisibility}
      onHide={() => setSupportModalVisibility(false)}
      animation={false}
      border
    >
      <Modal.Body>
        <Support onSubmit={() => setSupportModalVisibility(false)} />
      </Modal.Body>
    </Modal>
  );
};

const AppNavbar: React.FC<any> = ({
  setSupportModalVisibility,
}: {
  setSupportModalVisibility: Function;
}) => {
  const { logout } = useAuth0();
  const [navClassName, setNavClassName] = useState("");
  const history = useHistory();
  const location = useLocation();
  //console.log(history);
  const dispatch = useDispatch();

  const sidebar = useSelector((state: RootState) => state.utils.sidebar_state);

  const [activeMenu, setActiveMenu] = useState<any>(
    history.location.pathname.includes("account") ? "user" : "home",
  );

  const sidebarState = useSelector((state: RootState) => state.utils.sidebar_state);
  const userInfo = useSelector((state: RootState) => state.user.user);

  const handleScroll = useCallback(() => {
    // let header = document.getElementById('header');
    // let sticky = header?.offsetTop || 0;
    if (window.pageYOffset > 0) {
      if (navClassName === "") {
        setNavClassName("scrolled");
      }
    } else {
      if (navClassName) {
        setNavClassName("");
      }
    }
  }, [navClassName]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const onNavItemClick = (navItem: INavbarItems) => {
    const { path } = navItem;
    if (!path) return;

    setActiveMenu(navItem.key);

    history.push(path);
  };

  const toggleMenu = () => {
    dispatch(toggleSidebarAction());
  };

  useEffect(() => {
    document.body.style.overflow = sidebarState ? "hidden" : "unset";
  });

  const logoutPromise = () =>
    new Promise((s, r) => {
      dispatch({ type: "LOG_OUT" });
      logout({ returnTo: window.location.origin });
      setTimeout(() => {
        s(true);
      }, 3000);
    });

  const reference: any = useRef();
  const reference1: any = useRef();
  const reference2: any = useRef();
  const reference3: any = useRef();
  const reference0: any = useRef();

  const { hasPrivilege } = usePermission();

  // const onClickPersonalInfo = () => {
  //   if (!reference0?.current) {
  //     localStorage.setItem('navigate', 'personalinfo');
  //     window.location.href = '/dashboard/account';
  //   }
  //   window.scrollTo(0, reference0.current?.offsetTop);
  // };

  // const onClickSecurity = () => {
  //   if (!reference?.current) {
  //     localStorage.setItem('navigate', 'security');
  //     window.location.href = '/dashboard/account';
  //   }
  //   window.scrollTo(0, reference.current?.offsetTop);
  // };

  // const onClickPayment = () => {
  //   if (!reference1?.current) {
  //     localStorage.setItem('navigate', 'payment');
  //     window.location.href = '/dashboard/account';
  //   }
  //   window.scrollTo(0, reference1.current?.offsetTop);
  // };
  // const onClickSubscription = () => {
  //   if (!reference2?.current) {
  //     localStorage.setItem('navigate', 'subscription');
  //     window.location.href = '/dashboard/account';
  //   }
  //   window.scrollTo(0, reference2.current?.offsetTop);
  // };
  // const onClickNotifications = () => {
  //   if (!reference3?.current) {
  //     localStorage.setItem('navigate', 'notifications');
  //     window.location.href = '/dashboard/account';
  //   }
  //   window.scrollTo(0, reference3.current?.offsetTop);
  // };

  const onClickPersonalInfo = () => {
    history.push(`${routes.ACCOUNT}/personalinfo`);
  };
  const onClickSecurity = () => {
    history.push(`${routes.ACCOUNT}/security`);
  };
  const onClickPayment = () => {
    history.push(`${routes.ACCOUNT}/payment`);
  };
  const onClickSubscription = () => {
    history.push(`${routes.ACCOUNT}/subscription`);
  };
  const onClickNotifications = () => {
    history.push(`${routes.ACCOUNT}/notifications`);
  };
  const search = window.location.search;
  let isAdmin = useSelector((s: RootState) => s.user.isAdmin);
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const hasAdminDash = query.get("from") && query.get("from") === "admin-dashboard";

  // const [adminaccess, setAdminaccess] = useState(false);

  const handlePriaIconClick = () => {
    // incase the user is admin and the user is in admin portal we shouldnt direct him to dashboard

    //incase the user is non-admin we need to direct the user to dashboard

    if (!isAdmin) {
      history.push(routes.HOME);
    } else if (isAdmin && !(sessionStorage.getItem("backDashboard") === "true")) {
      return history.push(routes.HOME);
    } else if (isAdmin && sessionStorage.getItem("backDashboard") === "true") {
      return history.push(routes.ADMIN);
    }
    return history.push(routes.DASHBOARD);
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg ${navClassName}`}
        style={isAdmin ? { borderBottom: "1px solid ", borderBottomColor: " #E0E0E0" } : {}}
      >
        {sidebar && (
          <Sidebar
            onClickPersonalInfo={onClickPersonalInfo}
            securityHandler={onClickSecurity}
            paymentHandler={onClickPayment}
            subscriptionHandler={onClickSubscription}
            notificationHandler={onClickNotifications}
            setSupportModalVisibility={setSupportModalVisibility}
          />
        )}
        <div className="navbar-brand">
          <h4
            className="cursor-pointer"
            onClick={() => {
              handlePriaIconClick();
            }}
          >
            PRIA
          </h4>
        </div>

        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            {!isAdmin &&
              navbarItems.map((nav) => (
                <li
                  className={`${activeMenu === nav.key ? `nav-item active` : `nav-item`} `}
                  key={nav.key}
                  onClick={() => onNavItemClick(nav)}
                  style={{ padding: "16px 24px" }}
                >
                  {nav.label()}
                </li>
              ))}

            {/* {navbarItems.map((nav) => (
              <li
                className={`${activeMenu === nav.key ? `nav-item active` : `nav-item`} `}
                key={nav.key}
                onClick={() => onNavItemClick(nav)}
                style={{ padding: "16px 24px" }}
              >
                {nav.label()}
              </li>
            ))} */}

            {/* <li key="user" className={`nav-item dropdown`}>
                <a
                  href="#"
                  className={`nav-link dropdown-toggle ${activeMenu === 'user' ? `active` : ``}`}
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="navbarDropdown"
                >
                  {user?.name}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      setActiveMenu('user');
                      history.push(routes.ACCOUNT);
                    }}
                  >
                    <UserCircleIcon />
                    &nbsp; Account Settings
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      logout({ returnTo: window.location.origin });
                      dispatch({ type: 'LOG_OUT' });
                    }}
                    id="signout"
                  >
                    <LogoutIcon />
                    &nbsp; Logout
                  </a>
                </div>
              </li> */}

            <AppDropdown
              placeholder={
                userInfo
                  ? `${userInfo?.firstName}` +
                    " " +
                    `${userInfo.middleName ? userInfo.middleName : " "}` +
                    " " +
                    `${userInfo?.lastName}`
                  : ""
              }
              style={{ border: "none" }}
              labelStyle={{ padding: "16px 0px 16px 24px" }}
              isActive={activeMenu === "user"}
            >
              {!isAdmin && (
                <>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      setActiveMenu("user");
                      history.push(routes.ACCOUNT);
                    }}
                  >
                    <UserCircleIcon />
                    &nbsp; Account Settings
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      setSupportModalVisibility(true);
                    }}
                  >
                    {/* <UserCircleIcon /> */}
                    <img src={supportIcon} alt="support" style={{ width: "1.3rem" }} />
                    &nbsp; Support
                  </a>

                  <a
                    className="dropdown-item"
                    onClick={() => {
                      sessionStorage.setItem("backDashboard", "true");
                      //create search params object
                      const query = new URLSearchParams();
                      //set in query
                      query.set("from", "admin-dashboard");

                      history.push({
                        pathname: "/crs",
                        search: query.toString(), //convert object to string
                      });
                    }}
                  >
                    <UserCircleIcon />
                    &nbsp; Credit Score
                  </a>
                </>
              )}

              {!isAdmin && hasAdminDash && (
                <a
                  className="dropdown-item"
                  onClick={() => {
                    //  history.push(routes.ADMIN);
                    sessionStorage.removeItem("backDashboard");
                    history.push("/admin");
                    //  <Redirect to={routes.ADMIN} />;
                  }}
                >
                  <UserCircleIcon />
                  &nbsp; Go To Admin
                </a>
              )}
              {/* { && (
                <a
                  className="dropdown-item"
                  onClick={() => {
                    //  history.push(routes.ADMIN);
                    window.location.href = "/admin";
                    sessionStorage.removeItem("backDashboard");
                    //  <Redirect to={routes.ADMIN} />;
                  }}
                >
                  <UserCircleIcon />
                  &nbsp; Go To Admin
                </a>
              )} */}

              {isAdmin && sessionStorage.getItem("backDashboard") === "true" ? (
                <>
                  {" "}
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      //  history.push(routes.ADMIN);
                      sessionStorage.removeItem("backDashboard");
                      history.push("/admin/users");
                      //  <Redirect to={routes.ADMIN} />;
                    }}
                  >
                    &nbsp; <img src={backToAdmin} alt="back" />
                    &nbsp; Back To Admin
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      setActiveMenu("user");
                      history.push(routes.ACCOUNT);
                    }}
                  >
                    <UserCircleIcon />
                    &nbsp; Account Settings
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      setSupportModalVisibility(true);
                    }}
                  >
                    <img src={supportIcon} alt="support" style={{ width: "1.3rem" }} />
                    &nbsp; Support
                  </a>
                </>
              ) : isAdmin ? (
                <>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      sessionStorage.setItem("backDashboard", "true");
                      //create search params object
                      const query = new URLSearchParams();
                      //set in query
                      query.set("from", "admin-dashboard");

                      history.push({
                        pathname: "/dashboard",
                        search: query.toString(), //convert object to string
                      });
                    }}
                  >
                    <UserCircleIcon />
                    &nbsp; To Dashboard
                  </a>
                </>
              ) : (
                <></>
              )}
              {/* {!isAdmin && hasAdminDash && (
                <a
                  className="dropdown-item"
                  onClick={() => {
                    window.location.href = "/admin";
                    //  <Redirect to={routes.ADMIN} />;
                  }}
                >
                  <UserCircleIcon />
                  &nbsp; Go To Admin
                </a>
              )} */}

              <a
                className="dropdown-item"
                onClick={() => {
                  dispatch(logoutloader());
                  logoutPromise().then((res) => {
                    if (res === true) dispatch({ type: "LOG_OUT" });
                  });
                }}
                id="signout"
              >
                <LogoutIcon />
                &nbsp; Logout
              </a>
            </AppDropdown>
          </ul>
        </div>

        <img
          style={{ marginRight: 8 }}
          className="hamburgerMenu"
          src={hamburgerMenuIcon}
          onClick={() => toggleMenu()}
        />
      </nav>
    </>
  );
};

export default AppNavbar;
