/* eslint-disable no-unused-vars */
import React from "react";
import * as routes from "../../constants/routes";
import { ISideBarItemsAdmin } from "./types";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ReceiptIcon from "@mui/icons-material/Receipt";
import logIcon from "assets/img/logs.svg";
import transactionIcon from "assets/img/transactions.svg";
import clientDetailsIcon from "assets/img/clientDetails.svg";
//permission[{key:value}]
//{'key:valye':'key:value'}

export enum PermissionEnums {
  CUSTOMER_CHANGE_PASSWORD = "customer:change-password",
  CUSTOMER_DELETE = "customer:delete",
  CUSTOMER_GET = "customer:get",
  CUSTOMER_GET_ALL = "customer:get-all",
  CUSTOMER_NOTIFICATION = "customer:notification",
  CUSTOMER_PUT = "customer:put",
  CUSTOMER_VERIFY_EMAIL = "customer:verify-email",

  CARD_DELETE = "card:delete",
  CARD_GET = "card:get",
  CARD_POST = "card:post",
  CARD_PUT = "card:put",

  TRANSACTION_CONFIRM_PAYMENT_INTENT = "transaction:confirm-paymentintent",
  TRANSACTION_CYBERSOURCE = "transaction:cybersource",
  TRANSACTION_PAYIN = "transaction:payin",
  TRANSACTION_PAYOUT = "transaction:payout",
  TRANSACTION_STRIPE = "transaction:stripe",
}

export enum Privilege {
  CUSTOMER = "customer",
  CARD = "card",
  LOG = "log",
  TRANSACTION = "transaction",
}

export const sidebarItemsAdmin: ISideBarItemsAdmin[] = [
  {
    key: "home",
    label: "Home",
    path: routes.ADMIN,
    title: "Home",
    icon: ReceiptLongIcon,
    privilege: Privilege.LOG,
    permission: [{}],
    imgIcon: logIcon,
  },
  {
    key: "users",
    label: "users",
    path: routes.USERS,
    title: "Client Details",
    icon: PersonOutlineIcon,
    // permission: [{ 'a:a': 'a:a', b: 'b' }],
    privilege: Privilege.CUSTOMER,
    imgIcon: clientDetailsIcon,
    permission: [
      {
        "customer:change-password": "customer:change-password",
        "customer:delete": "customer:change-password",
        "customer:get": "customer:get",
        "customer:get-all": "customer:get-all",
        "customer:notification": "customer:notification",
        "customer:put": "customer:put",
        "customer:verify-email": "customer:verify-email",
      },
    ],
  },
  // {
  //   key: 'cardinfo',
  //   label: '`cardinfo',
  //   path: routes.CARDINFO,
  //   title: 'Card Info',
  //   icon: CreditCardIcon,
  //   privilege: Privilege.CARD,
  //   permission: [
  //     {
  //       'card:delete': 'card:delete',
  //       'card:get': 'card:get',
  //       'card:post': 'card:post',
  //       'card:put': 'card:put',
  //     },
  //   ],
  // },
  {
    key: "log",
    label: "log",
    path: routes.LOG,
    title: "Logs",
    icon: ReceiptLongIcon,
    privilege: Privilege.LOG,
    permission: [{}],
    imgIcon: logIcon,
  },
  {
    key: "transactions",
    label: "transactions",
    path: routes.TRANSACTION,
    title: "Transactions",
    icon: ReceiptIcon,
    privilege: Privilege.TRANSACTION,
    imgIcon: transactionIcon,
    permission: [
      {
        "transaction:confirm-paymentintent": "transaction:confirm-paymentintent",
        "transaction:cybersource": "transaction:cybersource",
        "transaction:payin": "transaction:payin",
        "transaction:payout": "transaction:payout",
        "transaction:stripe": "transaction:stripe",
      },
    ],
  },
  {
    key: "transactions summary",
    label: "transactions summary",
    path: routes.TRANSACTIONSUMMARY,
    title: "Transactions Summary",
    icon: ReceiptIcon,
    privilege: Privilege.TRANSACTION,
    imgIcon: transactionIcon,
    permission: [{}],
  },
  {
    key: "client feedback",
    label: "client feedback",
    path: routes.CLIENT_FEEDBACK,
    title: "Client Feedback",
    icon: ReceiptLongIcon,
    privilege: Privilege.LOG,
    imgIcon: logIcon,
    permission: [{}],
  },
  {
    key: "client message",
    label: "client message",
    path: routes.CLIENT_MESSAGE,
    title: "Client Message",
    icon: ReceiptLongIcon,
    privilege: Privilege.LOG,
    imgIcon: logIcon,
    permission: [{}],
  },
];
