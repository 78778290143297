import NavMenu from "components/NavMenu";
import React, { useEffect, useRef, useState } from "react";
import access_png from "assets/img/aboutpage/noun_device.png";
import tutorial_png from "assets/img/aboutpage/noun_tutorial.png";
import security_png from "assets/img/aboutpage/noun_Security.png";
import feedback_png from "assets/img/aboutpage/noun_Write_Message.png";
import support_png from "assets/img/aboutpage/noun_support.png";
import advocate_png from "assets/img/aboutpage/noun_Megaphone.png";
import "./about.scss";
import Footer from "components/Footer";
import { useAuth0 } from "@auth0/auth0-react";
import { isNull } from "lodash";
import AppNavbar from "components/Navbar";
import Sidebarhome from "components/SidebarHome/Sidebarhome";
import { useSelector } from "react-redux";
import { RootState } from "store";
import Sidebar from "components/Sidebar/Sidebar";
import { useHistory } from "react-router-dom";
import * as routes from "constants/routes";
import { SupportModal } from "components/Navbar/AppNavbar";
import { useSupportModal } from "hooks/useSidebar";

interface aboutPageProps {
  reference: any;
}

const AboutPage: React.FC<any> = ({ reference }: aboutPageProps) => {
  const { user, isAuthenticated } = useAuth0();
  const sidebarhome = useSelector((state: RootState) => state.utils.sidebar_state_home);
  const sidebar = useSelector((state: RootState) => state.utils.sidebar_state);
  const history = useHistory();

  useEffect(() => {
    handleResponsive();
    window.addEventListener("resize", handleResponsive);
    return () => window.removeEventListener("resize", handleResponsive);
  }, []);
  const [carousalMode, setCarousalMode] = useState<boolean>(
    window.innerWidth <= 500 ? true : false,
  );

  const frameTextRef = useRef<HTMLDivElement>(null);

  const handleResponsive = () => {
    const frameText = frameTextRef.current;

    if (frameText) {
      const frameHeight = frameText.getBoundingClientRect().height;
      document.documentElement.style.setProperty("--frame-height", `${frameHeight}px`);
    }

    if (window.innerWidth <= 500) {
      setCarousalMode(true);
    } else {
      setCarousalMode(false);
    }
  };

  const [setSupportModalVisibility, SupportComponent] = useSupportModal();

  return (
    <div className="about-page">
      {!isAuthenticated ? (
        <NavMenu />
      ) : (
        <AppNavbar setSupportModalVisibility={setSupportModalVisibility} />
      )}
      {!isAuthenticated && sidebar && <Sidebar />}

      <SupportComponent />

      <div className="frame11">
        <div className="frame11-text" ref={frameTextRef}>
          <div className="shape-outside"></div>
          <div className="shape-outside right"></div>
          <div>
            <h2 style={{ fontSize: 30, fontWeight: 600, lineHeight: 1.3 }}>
              At Pria we believe using a Credit Card should be an Option, not an Obligation.
            </h2>
            {carousalMode ? (
              <h5 style={{ fontSize: 18, lineHeight: 1.5, textAlign: "center" }}>
                With that spirit in mind we created Pria; Better Credit, Made Simple. Pria allows
                users to benefit from the convenience of having a credit card, without the burden of
                having to actively utilize the card just to build and maintain their credit score.
                We hope you Enjoy!
              </h5>
            ) : (
              <h5 style={{ fontSize: 20, lineHeight: 1.5, textAlign: "center" }}>
                With that spirit in mind we created Pria; Better Credit, Made Simple. Pria allows
                users to benefit from the convenience of having a credit card, without the burden of
                having to actively utilize the card just to build and maintain their credit score.
                We hope you Enjoy!
              </h5>
            )}
          </div>
        </div>
      </div>
      <div className="section">
        <div className="tile">
          <img src={access_png} alt="access" />
          <div className="nrm-text-bold">Access</div>
          <div className="nrm-text-reg" style={{ color: "#333333" }}>
            Pria can be accessed on any device <br />
            connected to the web.
          </div>
        </div>
        <div className="tile">
          <img src={tutorial_png} alt="access" />
          <div className="nrm-text-bold">Tutorial</div>
          <div className="nrm-text-reg" style={{ color: "#333333" }}>
            New to Pria? Learn to
            <br /> use and navigate across the
            <br /> app. Learn more
          </div>
        </div>
        <div className="tile">
          <img src={security_png} alt="access" />
          <div className="nrm-text-bold">Security</div>
          <div className="nrm-text-reg" style={{ color: "#333333" }}>
            Pria uses Bank Level Security, <br />
            256- Bit Encryption, and offers <br />
            Two-Factor Authentication.
          </div>
        </div>
      </div>
      <div className="section">
        <div className="tile">
          <img src={feedback_png} alt="access" />
          <div className="nrm-text-bold">Feedback</div>
          <div className="nrm-text-reg" style={{ color: "#333333" }}>
            We love to hear your feedback and
            <br /> suggestions. Leave a{" "}
            <b onClick={() => history.push(routes.FEEDBACK)} style={{ cursor: "pointer" }}>
              Review.
            </b>
          </div>
        </div>
        <div className="tile">
          <img src={support_png} alt="access" ref={reference} />
          <div className="nrm-text-bold">Support</div>
          <div className="nrm-text-reg" style={{ color: "#333333" }}>
            Need Help? Reach out to us, anytime.
          </div>
          {/* <div className="nrm-text-reg">+1 (421) 123-4567</div> */}
          <div
            className="nrm-text-bold"
            onClick={() => history.push(routes.CONTACT)}
            style={{ cursor: "pointer" }}
          >
            Contact Us
          </div>
        </div>
        <div className="tile">
          <img src={advocate_png} alt="access" />
          <div className="nrm-text-bold">Advocacy</div>
          <div className="nrm-text-reg" style={{ color: "#333333" }}>
            Join us to help improve financial literacy. <br /> Coming Soon!
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
