import { useAuth0 } from "@auth0/auth0-react";
import { FAQ } from "components/FAQPage/faq";
import Footer from "components/Footer";
import Navmenu from "components/NavMenu/Navmenu";
import AppNavbar from "components/Navbar";
import Sidebar from "components/Sidebar/Sidebar";
import { useSupportModal } from "hooks/useSidebar";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "store";
import "./faq.scss";

const FAQPage: React.FC = () => {
  const location = useLocation();
  //in this example faqOpen array is created with the same length as faq array, since we don't want them to open by default we fill the values with false, try with true to see the difference
  // const [faqOpen, setFaqOpen] = useState<any>(Array(FAQ.length).fill(false));
  const [faqOpen, setFaqOpen] = useState<any>({});
  const faqRefs = useRef<any[]>([]);
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const index = parseInt(hash.slice(1), 10);
      setFaqOpen((prevFaqOpen: any) => ({
        ...prevFaqOpen,
        [index]: true,
      }));
      scrollToFAQ(index-2);
    }
  }, [location]);
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const index = parseInt(hash.slice(1), 10);
      setTimeout(() => {
        scrollToFAQ(index-2);
      }, 0);
    }
  }, [location]);

  const scrollToFAQ = (index: number) => {
    if (faqRefs.current[index]) {
      faqRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const { user, isAuthenticated } = useAuth0();
  const sidebarhome = useSelector((state: RootState) => state.utils.sidebar_state_home);
  const sidebar = useSelector((state: RootState) => state.utils.sidebar_state);

  const [setSupportModalVisibility, SupportComponent] = useSupportModal();

  const FAQComp = ({ faq, index }: any) => {
    const isOpen = faqOpen[index] || false;
    const handleClick = () => {
      setFaqOpen((prevFaqOpen: any) => ({
        ...prevFaqOpen,
        [index]: !prevFaqOpen[index],
      }));
      scrollToFAQ(index-2);
    };

    return (
      <div className="faq" key={index} ref={(ref) => (faqRefs.current[index] = ref)}>
        <div className="faq-header" onClick={handleClick}>
          {faq.question}
          <div className="action">{!isOpen ? "+" : "-"}</div>
        </div>
        <div className="faq-answer">{isOpen ? faq?.answer : ""}</div>
      </div>
    );
  };

  return (
    <>
      <div className="faq-page">
        {!isAuthenticated ? (
          <Navmenu />
        ) : (
          <AppNavbar setSupportModalVisibility={setSupportModalVisibility} />
        )}
        {!isAuthenticated && sidebar && <Sidebar />}

        <SupportComponent />

        <div className="faqs">
          <h3>Frequently Asked Questions</h3>
          <div className="faq-margin"></div>
          {/* {FAQ.map((faq: any, key: number) => (
            <FAQComp faq={faq} index={key} key={key} faqOpen={faqOpen[key]} />
          ))} */}
          {FAQ.map((faq: any, index: number) => (
            <FAQComp faq={faq} index={index} key={index} faqOpen={faqOpen[index]} id={index} />
          ))}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default FAQPage;
