import http from "services/http";
import * as urls from "constants/urls";
import { FilterOptions } from "components/DynamicTable/FilterOptions";

export const getFeedbacks = async (
    token: string,
    { filter, ...filterOptions }: FilterOptions,
  ) => {
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const capturedAt = filter?.capturedAt;
  
    const response = await http.get(`${urls.FEEDBACKS}/`, {
      params: {
        sort: "id",
        ...filterOptions,
        order: filterOptions?.order?.toUpperCase(),
        startDate: capturedAt && capturedAt?.startDate,
        endDate: capturedAt && capturedAt?.endDate,
      },
    });
    return response?.data?.data;
  };

  export const getContacts = async (
    token: string,
    { filter, ...filterOptions }: FilterOptions,
  ) => {
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const capturedAt = filter?.capturedAt;
  
    const response = await http.get(`${urls.CONTACTS}/`, {
      params: {
        sort: "id",
        ...filterOptions,
        order: filterOptions?.order?.toUpperCase(),
        startDate: capturedAt && capturedAt?.startDate,
        endDate: capturedAt && capturedAt?.endDate,
      },
    });
    return response?.data?.data;
  };