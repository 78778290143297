import _ from "lodash";
import React, { useCallback } from "react";
import { Spinner } from "react-bootstrap";

interface IAppButtonProps {
  className: string;
  children?: any;
  style?: any;
  onClick?: any;
  fullWidth?: boolean;
  size?: string;
  disabled?: boolean;
  type?: any;
  isLoading?: boolean;
  title?: string;
  id?: string;
}
// const DEBOUNCED_TIMEOUT = 1000;

const AppButton: React.FC<IAppButtonProps> = ({
  className,
  isLoading,
  // onClick,
  ...props
}: IAppButtonProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const onClickDebounced = useCallback(_.throttle(onClick, DEBOUNCED_TIMEOUT), [onClick]);
  return (
    <button
      title={props.title}
      className={`appButton${props.size ? `-${props.size}` : ``} ${className}`}
      {...props}
      // onClick={onClickDebounced}
    >
      {props.children}
      {isLoading && (
        <Spinner
          animation="border"
          role="status"
          variant="light"
          size="sm"
          style={{ marginLeft: "8px" }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </button>
  );
};

export default AppButton;
