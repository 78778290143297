import React, { ComponentType, ReactNode, useMemo, useRef, useState } from "react";
import styles from "./dTable.module.scss";

import { Box, IconButton, Stack, Typography } from "@mui/material";
import { GenericFn } from "common";
import { DateRange } from "react-date-range";
import { DebounceInput } from "react-debounce-input";
import { FaCalendar, FaFilter, FaSearch } from "react-icons/fa";
import Select, { StylesConfig } from "react-select";
import { useFilter } from "./FilterOptions";
import { RangeKeyDict } from "react-date-range";

export type FilterList = FilterInputUnion[];

export interface TableFilterProps {
  title?: string;
  filterList: FilterList;
  onChange?: GenericFn;
  showSearch?: boolean;
  onSearchChange?: GenericFn;
  actions?: ComponentType<any>[];
  rows?: unknown[];
  selectedCheckboxIndexes?: (number | null)[];
}

export type FilterInputType = "text" | "select" | "date" | "number";
export type SelectItem = { label: string; value: any };
export type DateItem = { start?: number; end?: number };

export interface FilterInputProps {
  field: string;
  label?: string;
  type: FilterInputType;
  value?: any;
  onChange?: GenericFn;
}
export type FilterInputUnion = FilterTextProps | FilterSelectProps | FilterDateProps;
export interface FilterTextProps extends FilterInputProps {
  type: "text" | "number";
}
export interface FilterSelectProps extends FilterInputProps {
  type: "select";
  options: SelectItem[];
}
export interface FilterDateProps extends FilterInputProps {
  type: "date";
  value?: DateItem;
}
export const TableFilter: React.FC<TableFilterProps> = (props) => {
  const {
    title,
    filterList = [],
    showSearch = true,
    onChange,
    onSearchChange,
    actions = [],
    selectedCheckboxIndexes = [],
    rows = [],
  } = props;

  const [showFilter, setShowFilter] = useState(false);
  const toggleFilter = () => setShowFilter((s) => !s);

  const { filterOptions, setSearch, setFilter, setPage } = useFilter();
  /**
   * handle search
   * @param e
   */
  const handleSearch = (e: any) => {
    const value = e.target.value;
    setPage(0); //reset page
    setSearch(value);
    onSearchChange?.(value);
  };
  const handleFilterChange = (e: any) => {
    setPage(0); //reset page
    setFilter(e);
    onChange?.(e);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" pt="6px" pb={2} pr={{ sm: 2 }}>
        <Typography variant="h5" fontFamily="Lato" flex={1} fontSize="2rem" fontWeight={700} mr={2}>
          {title}
        </Typography>
        {actions && (
          <Box marginRight={2}>
            {actions.map((Action, index) => (
              <Box key={`action_${index}`} marginRight={2}>
                <Action
                  rows={rows}
                  selectedRows={selectedCheckboxIndexes
                    .filter((x) => x !== null)
                    .map((index: any) => rows[index])}
                />
              </Box>
            ))}
          </Box>
        )}
        {Boolean(filterList.length) && (
          <IconButton className={styles.filter_button} onClick={toggleFilter}>
            <FaFilter />
          </IconButton>
        )}
        {showSearch && (
          <Box className={styles.filter_input_container}>
            <FaSearch className={styles.icons} />
            <DebounceInput
              placeholder={"Search..."}
              className={styles.filter_input}
              value={filterOptions.search}
              onChange={handleSearch}
              debounceTimeout={500}
            />
            {/* {Boolean(filterOptions.search?.length) && (
              <FaTimes className={styles.icons} onClick={() => setSearch("")} />
            )} */}
          </Box>
        )}
      </Stack>

      {showFilter && <FilterContainer filterList={filterList} onChange={handleFilterChange} />}
    </>
  );
};

export interface FilterContainerProps {
  onChange?: GenericFn;
  filterList: FilterList;
}

const FilterContainer: React.FC<FilterContainerProps> = (props) => {
  const { onChange, filterList = [] } = props;

  const filterMapInit = useMemo(() => {
    const map = new Map();
    filterList.map((filter: FilterInputUnion) => {
      map.set(filter.field, filter.value);
    });
    return map;
  }, [filterList]);

  const filterMapRef = useRef(filterMapInit);

  const handleFilterChange = (filterKey: string, value: any) => {
    filterMapRef.current = filterMapRef.current.set(filterKey, value);
    const output = Object.fromEntries(filterMapRef.current);
    onChange?.(output);
  };

  /**
   * Function to render the inputs
   * @param filter
   * @returns
   */
  const filterInputRender = (filter: FilterInputUnion) => {
    const filterCurry = (value: any) => handleFilterChange(filter.field, value);

    const uniqueKey = `${filter.type}_${filter.field}_${filter.value}`;

    switch (filter.type) {
      default:
        return <FilterText {...filter} onChange={filterCurry} key={uniqueKey} />;
      case "select":
        return <FilterSelect {...filter} onChange={filterCurry} key={uniqueKey} />;
      case "date":
        return <FilterDate {...filter} onChange={filterCurry} key={uniqueKey} />;
    }
  };

  return <Box className={styles.filter_container}>{filterList.map(filterInputRender)}</Box>;
};

/**
 * Filter text component
 * @param props
 * @returnsconsole.log(ranges);
 */
export const FilterText: React.FC<FilterTextProps> = (props) => {
  const { value, onChange, label, type } = props;

  //make local controlled
  const [localVal, setLocalVal] = useState(value);

  const handleChange = (e: any) => {
    let value = e.target?.value;
    if (type === "number") {
      value = value.replace(/(?![\d])./g, "");
    }
    setLocalVal(value);
    onChange?.(value);
  };
  return (
    <Box>
      <Typography fontSize={"0.8rem"} mb={0.5}>
        {label}
      </Typography>
      <DebounceInput
        placeholder={"Search..."}
        className={`${styles.filter_input} ${styles.normal}`}
        value={localVal}
        onChange={handleChange}
        debounceTimeout={500}
      />
    </Box>
  );
};

const filterSelectStyles: StylesConfig = {
  control: (provided: any) => ({
    ...provided,
    height: "100%",
    borderRadius: "0.5rem",
  }),
  container: (p: any) => {
    return { ...p, flex: 1 };
  },
};

/**
 * Filter select component
 * @param props
 * @returns
 */
export const FilterSelect: React.FC<FilterSelectProps> = (props) => {
  const { options, value, onChange, label } = props;

  //option memo
  const optionMap = useMemo(() => {
    const map = new Map<any, SelectItem>();
    options?.forEach((item) => {
      map.set(item.value, item);
    });
    return map;
  }, [options]);

  const handleChange = (selectItem: any) => {
    onChange?.(selectItem?.value);
  };

  return (
    <Stack direction="column">
      <Typography fontSize={"0.8rem"} mb={0.5}>
        {label}
      </Typography>
      <Select
        styles={filterSelectStyles}
        options={options}
        defaultValue={optionMap.get(value) || options[0]}
        onChange={handleChange}
      />
    </Stack>
  );
};

/**
 * Filter date component
 * @param props
 * @returns
 */
export const FilterDate: React.FC<FilterDateProps> = (props) => {
  const { value, onChange, label } = props;

  const selectionInitRange = useMemo(() => {
    const selection: Record<string, any> = {
      key: "selection",
    };
    const startDate = value?.start;
    const endDate = value?.end;
    selection.startDate = startDate ? new Date(startDate) : new Date();
    selection.endDate = endDate ? new Date(endDate) : new Date();
    return selection;
  }, [value]);

  const [showDate, setShowDate] = useState(false);
  const [selectionRange, setSelectionRange] = useState(selectionInitRange);

  const handleChange = (ranges: any) => {
    const selection = ranges?.selection;

    setSelectionRange((s) => {
      s.startDate = selection?.startDate;
      s.endDate = selection?.endDate;

      return s;
    });
    onChange?.({
      startDate: selection?.startDate.toISOString(),
      endDate: selection?.endDate.toISOString(),
    });
  };

  const toggleDate = () => setShowDate((s) => !s);

  return (
    <Box sx={{ position: "relative", display: "flex", alignItems: "center" }}>
      <FaCalendar fontSize={30} color={showDate ? "#a3a3a3" : "#000"} onClick={toggleDate} />
      {showDate && (
        <Box position="absolute" top={"100%"} zIndex="1">
          <DateRange
            color={"#f00"}
            ranges={[selectionRange]}
            maxDate={new Date()}
            onChange={handleChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default TableFilter;
