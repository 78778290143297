import DynamicTable from "components/DynamicTable";
import { useFilter } from "components/DynamicTable/FilterOptions";
import useGetAccessToken from "hooks/useGetAccessToken";
import moment from "moment";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { ToastContainer } from "react-toastify";
import { getFeedbacks } from "api/clientMessages";
import { TransactionDataType } from "../AdminPortal/Transactions/Transactions";

const ClientFeedback = () => {
  const { filterOptions } = useFilter({ limit: 10, order: "desc" });
  const token = useGetAccessToken();
  const { data: allFeedbackData, isLoading, refetch } = useQuery(
    ["all feedbacks", token, filterOptions],
    () => token && getFeedbacks(token, filterOptions),
    {
      // keepPreviousData: true,
    },
  );

  const feedbackTableData = useMemo<TransactionDataType[]>(() => {
    try {
      return allFeedbackData?.rows.map((txn: any) => {
        return {
          id: txn?.id,
          createdAt: moment(txn?.createdAt).format("YYYY-MM-DD HH:mm"),
          message: txn?.message,
          star: txn?.star,
        };
      });
    } catch (err) {
      console.log(err);

      return [];
    }
  }, [allFeedbackData]);

  console.log("feedbackTableData", allFeedbackData);

  return (
    <>
      <ToastContainer />

      <DynamicTable<TransactionDataType>
        title="Client Feedback"
        td={feedbackTableData}
        th={[
          { accessor: "createdAt", h: "Created At", isSortable: true },
          { accessor: "star", h: "Star", isSortable: false },
          { accessor: "message", h: "Message", isSortable: false },
        ]}
        totalPages={allFeedbackData?.lastPage}
        totalResults={allFeedbackData?.totalCount}
        isLoading={isLoading}
        showMore={""}
        // showFilter
        // filterList={[{ field: "createdAt", type: "date", label: "Created-At" }]}
      />
    </>
  );
};

export default ClientFeedback;
