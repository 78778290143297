import { useAuth0 } from "@auth0/auth0-react";
import master_logo from "assets/img/master-logo.png";
import visa_logo from "assets/img/visa-logo.png";
import { CardBrandReverse } from "constants/cards";
import React from "react";
import { useSelector } from "react-redux";
import "./cardDisplay.scss";
import { RootState } from "store";

interface ICardDisplay {
  lastFour: number | string;
  id: string;
  handleEditCard: any;
  brand: any;
}

const CardDisplay: React.FC<ICardDisplay> = ({
  lastFour,
  id,
  handleEditCard,
  brand,
}: ICardDisplay) => {
  const { user } = useAuth0();

  const brandName = CardBrandReverse?.[brand];
  const userInfo = useSelector((state: RootState) => state.user.user);

  const userName = `${userInfo?.firstName}` + " " + `${userInfo?.lastName}`;

  return (
    <div
      // className={`card-display${brand === "MasterCard" ? ` master` : ``} `}
      className="card-display"
      onClick={() => handleEditCard(id)}
    >
      <div className="card-display-row">
        <div className="sm-text-reg"> Credit</div>
        <img
          src={brandName === "mastercard" ? master_logo : visa_logo}
          alt="Visa"
          style={{ height: brandName === "mastercard" ? "36px" : "14px" }}
        />
      </div>
      <div className="card-display-row">
        <div className="col p-0">
          <div className="sm-text-reg">{userName}</div>
          <div className="sm-text-reg"> {`****${lastFour}`} </div>
        </div>
        <div onClick={() => handleEditCard(id)}>
          <span className="iconify" data-icon="uil-edit" data-inline="false"></span>
        </div>
      </div>
    </div>
  );
};

export default CardDisplay;
