import { getAllSummaryTransactions } from "api/admin";
import DynamicTable from "components/DynamicTable";
import { useFilter } from "components/DynamicTable/FilterOptions";
import { CardBrandReverse } from "constants/cards";
import useGetAccessToken from "hooks/useGetAccessToken";
import moment from "moment";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { ToastContainer } from "react-toastify";
import "../Transactions/Transaction.scss";
import { TransactionDataType } from "../Transactions/Transactions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Modal } from "react-bootstrap";
import TransactionsSummaryModal from "./TransectionSummaryModal";

const TransactionsSummary = () => {
  const { filterOptions } = useFilter({ limit: 10, order: "desc" });
  const token = useGetAccessToken();
  const { data: allTransactionsData, isLoading, refetch } = useQuery(
    ["all summary transactions", token, filterOptions],
    () => token && getAllSummaryTransactions(token, filterOptions),
    {
      // keepPreviousData: true,
    },
  );
  const [transactionsData, setTransactionsData] = useState([]);
  const [txnModal, setTxnModal] = useState<boolean>(false);
  // debugger;

  const transactionModal = () => {
    return (
      <Modal
        show={txnModal}
        onHide={() => {
          setTxnModal(false);
        }}
        size="xl"
      >
        <Modal.Body style={{ padding: "12px 24px" }}>
          <TransactionsSummaryModal transactionsData={transactionsData} />
        </Modal.Body>
      </Modal>
    );
  };
  const transactionTableData = useMemo<TransactionDataType[]>(() => {
    try {
      return allTransactionsData?.rows.map((txn: any, index: number) => {
        return {
          id: txn?.id,
          cardId: txn?.card?.cardId,
          createdAt: moment(txn?.createdAt).format("YYYY-MM-DD HH:mm"),
          sn: (allTransactionsData?.currentPage - 1) * allTransactionsData?.rowsCount + index + 1,
          transactionId: txn?.transactionId,
          email: txn?.customer?.email,
          cardDetails: `${CardBrandReverse?.[txn?.card?.brand] || ""}****${
            txn?.card?.lastFour || ""
          }`,
          status: `${txn?.transactionState}`,
          amount: `$${parseFloat(txn?.amount).toFixed(2)}`,
          remarks: txn?.remarks,

          view: (
            <VisibilityIcon
              onClick={() => {
                setTransactionsData(txn);
                setTxnModal(true);
              }}
            />
          ),
        };
      });
    } catch (err) {
      console.log(err);

      return [];
    }
  }, [allTransactionsData]);

  return (
    <>
      <ToastContainer />
      {transactionModal()}
      <DynamicTable<TransactionDataType>
        title="Transactions Summary"
        td={transactionTableData}
        th={[
          { accessor: "createdAt", h: "Created At", isSortable: true },
          { accessor: "cardDetails", h: "Card Details", isSortable: true },
          { accessor: "email", h: "Email", isSortable: true },

          { accessor: "amount", h: "Amount", isSortable: true },
          { accessor: "status", h: "Transaction State", isSortable: true },
          { accessor: "remarks", h: "Remarks", isSortable: true },
          { accessor: "view", h: "Details", isSortable: false },
        ]}
        totalPages={allTransactionsData?.lastPage}
        totalResults={allTransactionsData?.totalCount}
        isLoading={isLoading}
        showMore={""}
        showFilter
        filterList={[
          { field: "capturedAt", type: "date", label: "Created-At" },
          {
            field: "transactionState",
            type: "text",
            label: "Transaction State",
          },
        ]}
      />
    </>
  );
};

export default TransactionsSummary;
