import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import AppInput from "common/AppInput";
import Footer from "components/Footer";
import AppNavbar from "components/Navbar";
import Navmenu from "components/NavMenu/Navmenu";
import Sidebar from "components/Sidebar/Sidebar";
import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { API_URL } from "services/http";
import { RootState } from "store";
import * as Yup from "yup";
import "./Contact.scss";

function Contact() {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();

  const sidebar = useSelector((state: RootState) => state.utils.sidebar_state);
  const [topics, setTopics] = useState("");

  function handleType(e: any) {
    setTopics(e.target.value);
  }

  const addingAndRemovingCards = () => {
    setTopics("Adding or Removing Cards");
  };

  const BillingAndPlans = () => {
    setTopics("Billing and Plans");
  };
  const security = () => {
    setTopics("Security");
  };
  const Notifications = () => {
    setTopics("Suggestions");
  };
  const CreditScore = () => {
    setTopics("Credit Score & Report");
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email,
      type: topics || "",
      message: "",
      // topic: topics || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Email is required"),
      // type: Yup.string().required("Required"),
      message: Yup.string().required("Message is required"),
      type: Yup.string().required("Topic is required"),
    }),
    onSubmit: async (values: any) => {
      handleContact(values);
    },
  });

  const handleContact = async (values: any) => {
    await axios
      .post(`${API_URL}/contacts`, { ...values })
      .then((response) => {
        toast("Message Sent Successfully");
      })
      .catch((error) => {
        toast("Please enter all the fields with valid data");
        console.log(JSON.stringify(error));
      });
  };

  return (
    <div className="page">
      <div className="hero-sectionn">
        {!isAuthenticated ? <Navmenu /> : <AppNavbar />}
        {!isAuthenticated && sidebar && <Sidebar />}
        <div className="mainn">
          <ToastContainer />
          <div className="sdpadding">
            <h3>Contact Us</h3>
            <h6>
              Already use Pria?{" "}
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => {
                  loginWithRedirect({
                    screen_hint: "sign_up",
                  });
                }}
              >
                Sign in
              </span>{" "}
              so we can tailor your support experience. If that’s not possible, we’d still like to
              hear from you.
            </h6>
          </div>

          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              Select a topic*
              <div className="topics__list">
                <div
                  tabIndex={0}
                  className={
                    topics === "Adding or Removing Cards" ? "md-text-bolds" : "md-text-bold"
                  }
                  style={{
                    paddingTop: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    border: "2px solid #081149",
                    borderRadius: "16px",
                  }}
                  onClick={addingAndRemovingCards}
                >
                  {" "}
                  Adding or Removing cards
                </div>
                <div
                  tabIndex={0}
                  className={topics === "Billing and Plans" ? "md-text-bolds" : "md-text-bold"}
                  style={{
                    paddingTop: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    border: "2px solid #081149",
                    borderRadius: "16px",
                  }}
                  onClick={BillingAndPlans}
                >
                  {" "}
                  Billing and Plans
                </div>
                <div
                  tabIndex={0}
                  className={topics === "Security" ? "md-text-bolds" : "md-text-bold"}
                  style={{
                    paddingTop: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    border: "2px solid #081149",
                    borderRadius: "16px",
                  }}
                  onClick={security}
                >
                  {" "}
                  Security
                </div>
                <div
                  tabIndex={0}
                  className={topics === "Suggestions" ? "md-text-bolds" : "md-text-bold"}
                  style={{
                    paddingTop: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    border: "2px solid #081149",
                    borderRadius: "16px",
                  }}
                  onClick={Notifications}
                >
                  {" "}
                  Suggestions
                </div>
                <div
                  tabIndex={0}
                  className={topics === "Credit Score & Report" ? "md-text-bolds" : "md-text-bold"}
                  style={{
                    paddingTop: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    border: "2px solid #081149",
                    borderRadius: "16px",
                  }}
                  onClick={CreditScore}
                >
                  {" "}
                  Credit Score & Report
                </div>
              </div>
              <Form.Group as={Col} className="contact-mar">
                <Form.Label className="topic-label">Or tell us what you need help with*</Form.Label>
                <Col className="contact-mar">
                  <Row>
                    <Col sm={12}>
                      <AppInput
                        placeholder="Enter a topic, like “notifications”"
                        name="type"
                        onChange={handleType}
                        value={formik.values.type}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.type && formik.touched.type && (
                        <div className="invalid-feedback-text">{formik.errors.type}</div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
              <Form.Group as={Col} className="contact-mar">
                <Form.Label>Email*</Form.Label>
                <Col className="contact-mar">
                  <Row>
                    <Col sm={12}>
                      <AppInput
                        placeholder="Enter your email"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        type="email"
                      />
                      {formik.errors.email && formik.touched.email && (
                        <div className="invalid-feedback-text">{formik.errors.email}</div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
              <Form.Group as={Col} className="contact-mar">
                <Form.Label>Message*</Form.Label>
                <Col className="contact-mar">
                  <Row>
                    <Col sm={12}>
                      <textarea
                        name="message"
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                      {formik.errors.message && formik.touched.message && (
                        <div className="invalid-feedback-text">{formik.errors.message}</div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
              <div style={{ color: "#fe424d" }} className="contact-notice">
                Notice: Pria will never ask for, nor should you provide, your account number, social
                security number, name, address or password in an email or text message.
              </div>
              <button className="primary-button" style={{ width: "100%", marginTop: 20 }}>
                Submit
              </button>
            </Form>
          </FormikProvider>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
