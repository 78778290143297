import { useAuth0 } from "@auth0/auth0-react";
import { logoutloader } from "actions/logoutloader";
import { openSupportMobile, toggleSidebarAction } from "actions/utils";
import cross from "assets/img/cross.png";
import AppButton from "common/AppButton";
import { LogoutIcon } from "common/AppIcons";
import AppInput from "common/AppInput";
import { INavbarItems } from "components/Navbar/INavbarItems";
import { navbarItems } from "components/Navbar/navbar.config";
import * as routes from "constants/routes";
import { IsAdminContext } from "Context/AdminContext";
import { useSidebar } from "hooks/useSidebar";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { deleteAccountAPI } from "services/users";
import { RootState } from "store";
import { handleError } from "utils/handleError";
import { checkResponseStatus, handleSuccess } from "utils/handleSuccess";
import "./sidebar.scss";

interface SidebarProps {
  handleSecurity: Function;
  reference: any;
  reference1: any;
  securityHandler: Function;
  paymentHandler: Function;
  subscriptionHandler: Function;
  notificationHandler: Function;
  onClickPersonalInfo: Function;
  setSupportModalVisibility: Function;
}

const Sidebar: React.FC<any> = ({
  securityHandler,
  paymentHandler,
  subscriptionHandler,
  notificationHandler,
  onClickPersonalInfo,
  setSupportModalVisibility,
}: SidebarProps) => {
  const dispatch = useDispatch();
  const { logout, user, getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const sidebar = useSelector((state: RootState) => state.utils.sidebar_state);
  const preventToggle = useSelector((state: RootState) => state.utils.prevent_toggle);

  const [activeMenu, setActiveMenu] = useState<any>("home");
  const [deleteCode, setDeleteCode] = useState("");
  const [confirmDeleteWithCode, setConfirmDeleteWithCode] = useState(false);
  const userInfo = useSelector((state: RootState) => state.user.user);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  let isAdmin = useSelector((s: RootState) => s.user.isAdmin);

  // console.log(window.location.pathname);
  const [path, setPath] = useState(window.location.pathname);
  let pathnames = window.location.pathname;
  useEffect(() => {
    setPath(window.location.pathname);
  }, [pathnames]);

  const { ref, isVisible, setVisible } = useSidebar(
    true,
    toggleSidebarAction,
    sidebar,
    preventToggle,
  );
  const onNavItemClick = (navItem: INavbarItems) => {
    //dispatch(preventToggleAction(true));
    dispatch(toggleSidebarAction());
    const { path } = navItem;
    if (!path) return;
    setActiveMenu(navItem.key);
    history.push(path);
    //return <Redirect exact to={path} />;
  };
  useEffect(() => {
    getAccessTokenSilently()
      .then((token: any) => {
        if (token) {
          localStorage.setItem("token", token);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // es
  const handleSupport = () => {
    dispatch(openSupportMobile);
  };
  const handleRemoveAccount = async () => {
    try {
      let token = await getAccessTokenSilently();
      setLoading(true);
      if (user && user?.sub) {
        const response = await deleteAccountAPI(user.sub, token);
        if (response && response.data && checkResponseStatus(response.data.httpCode)) {
          setLoading(false);
          setConfirmDeleteWithCode(true);
        }
        // console.log(response);
      }
    } catch (error) {
      handleError("Error while deleting Account");
    } finally {
      setConfirmDelete(false);
      setLoading(false);
    }
  };

  const handleRemoveAccountWithCode = async () => {
    try {
      let token = await getAccessTokenSilently();
      setLoading(true);
      if (user && user?.sub) {
        const response = await deleteAccountAPI(user.sub, token, deleteCode);
        if (response && response.data && checkResponseStatus(response.data.httpCode)) {
          setLoading(false);
          handleSuccess("Account Deleted successfully");
          dispatch(logoutloader());
          dispatch({ type: "LOG_OUT" });
          logout({ returnTo: window.location.origin });
        }
        // console.log(response);
      }
    } catch (error) {
      handleError("Error while deleting Account");
    } finally {
      setConfirmDeleteWithCode(false);
      setConfirmDelete(false);
      setLoading(false);
    }
  };

  const renderConfirmDeleteModal = () => {
    return (
      <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)} animation={false}>
        <Modal.Body style={{ padding: "12px 18px" }}>
          <div className="nrm-text-reg" style={{ textAlign: "center", padding: "16px 0px" }}>
            Are you sure you want to delete your account?
            <label htmlFor="info">
              {loading && `A code will be sent to the phone number linked to this account`}
            </label>
          </div>

          <div className="form-row justify-content-center">
            <AppButton
              size="sm"
              className="secondary m-1"
              onClick={() => handleRemoveAccount()}
              isLoading={loading}
              disabled={loading}
            >
              Confirm
            </AppButton>
            <AppButton size="sm" className="primary m-1" onClick={() => setConfirmDelete(false)}>
              Cancel
            </AppButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const renderDeleteCodeModal = () => {
    return (
      <Modal
        show={confirmDeleteWithCode}
        onHide={() => setConfirmDelete(false)}
        animation={false}
        border
      >
        <Modal.Body style={{ padding: "12px 18px", alignItems: "center" }}>
          <div className="nrm-text-reg" style={{ padding: "16px 0px" }}>
            Enter the deletion code sent to you phone
          </div>
          <AppInput
            type="text"
            placeholder="Code (xxxx)"
            name="deleteCode"
            onChange={(e: any) => {
              setDeleteCode(e.target.value);
            }}
          />
          <div className="form-row justify-content-center">
            <AppButton
              size="sm"
              className="secondary justify-self-center"
              onClick={() => handleRemoveAccountWithCode()}
              isLoading={loading}
              disabled={loading}
            >
              Confirm
            </AppButton>
            <AppButton
              size="sm"
              className="primary ml-1"
              onClick={() => setConfirmDeleteWithCode(false)}
            >
              Cancel
            </AppButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      {renderConfirmDeleteModal()}
      {renderDeleteCodeModal()}

      {isVisible && sidebar && (
        <div
          role="dialog"
          aria-modal={true}
          className="offcanvas offcanvas-end show"
          tabIndex={-1}
          style={{ visibility: "visible" }}
          ref={sidebar ? ref : null}
        >
          <div className="offcanvas-header" style={{ flexDirection: "row-reverse" }}>
            <img src={cross} onClick={() => dispatch(toggleSidebarAction())}></img>
          </div>
          <div className="offcanvas-body">
            {/* <div
              className="sidebar-item-spacer"
              style={{ marginLeft: "10px", marginTop: "0.5rem" }}
              onClick={() => {
                dispatch(toggleSidebarAction());
                history.push("/");
              }}
            >
              Pria
            </div> */}
            <ul className="navbar-nav">
              {navbarItems.map((nav: any, key: number) => (
                // <Link to={nav.path} key={key}>

                <li
                  className={`${path === "/dashboard" ? `nav-item active` : `nav-item`} `}
                  key={nav.key}
                  onClick={() => {
                    onNavItemClick(nav);
                  }}
                >
                  {nav.label()}
                </li>
                // </Link>
              ))}
            </ul>
            {/* <div style={{marginLeft:'10px', marginTop:'0.5rem'}} onClick={()=>{
                 dispatch(toggleSidebarAction()); handleSupport()
                }}>Support</div> */}

            <div
              className="nav-item sidebar-item-spacer"
              style={{ marginTop: "0.5rem" }}
              //  className={`${path === () ? `nav-item active sidebar-item-spacer` : `nav-item sidebar-item-spacer`} `}
              onClick={() => {
                history.push({
                  pathname: "/crs",
                });
              }}
            >
              Credit Score
            </div>
            <div
              className="nav-item sidebar-item-spacer"
              style={{ marginTop: "0.5rem" }}
              //  className={`${path === () ? `nav-item active sidebar-item-spacer` : `nav-item sidebar-item-spacer`} `}
              onClick={() => {
                dispatch(toggleSidebarAction());
                setSupportModalVisibility?.(true);
              }}
            >
              Support
            </div>
            <div
              // className="sidebar-item-spacer"
              className={`${
                path === "/dashboard/account/personalinfo"
                  ? `nav-item active sidebar-item-spacer`
                  : `nav-item sidebar-item-spacer`
              } `}
              style={{ marginTop: "0.5rem" }}
              onClick={() => {
                onClickPersonalInfo();
                dispatch(toggleSidebarAction());
              }}
            >
              Personal Information
            </div>

            <div
              // className="sidebar-item-spacer"
              className={`${
                path === "/dashboard/account/security"
                  ? `nav-item active sidebar-item-spacer`
                  : `nav-item sidebar-item-spacer`
              } `}
              onClick={() => {
                securityHandler();
                dispatch(toggleSidebarAction());
              }}
              style={{ marginTop: "0.5rem" }}
            >
              Security
            </div>

            <div
              // className="sidebar-item-spacer"
              className={`${
                path === "/dashboard/account/payment"
                  ? `nav-item active sidebar-item-spacer`
                  : `nav-item sidebar-item-spacer`
              } `}
              onClick={() => {
                paymentHandler?.();
                dispatch(toggleSidebarAction());
              }}
              style={{ marginTop: "0.5rem" }}
            >
              Payment Method
            </div>

            <div
              // className="sidebar-item-spacer"
              className={`${
                path === "/dashboard/account/subscription"
                  ? `nav-item active sidebar-item-spacer`
                  : `nav-item sidebar-item-spacer`
              } `}
              onClick={() => {
                subscriptionHandler?.();
                dispatch(toggleSidebarAction());
              }}
              style={{ marginTop: "0.5rem" }}
            >
              Subscription
            </div>

            <div
              // className="sidebar-item-spacer"
              className={`${
                path === "/dashboard/account/notifications"
                  ? `nav-item active sidebar-item-spacer`
                  : `nav-item sidebar-item-spacer`
              } `}
              onClick={() => {
                notificationHandler?.();
                dispatch(toggleSidebarAction());
              }}
              style={{ marginTop: "0.5rem" }}
            >
              Notifications
            </div>
            {isAdmin && sessionStorage.getItem("backDashboard") === "true" ? (
              <div
                className="nav-item sidebar-item-spacer"
                onClick={() => {
                  sessionStorage.removeItem("backDashboard");
                  dispatch(toggleSidebarAction());
                  history.push("/admin");
                }}
                style={{ marginTop: "0.5rem" }}
              >
                To Admin
              </div>
            ) : null}
            <div
              className="nav-item sidebar-item-spacer"
              style={{ color: "red", marginTop: "0.5rem" }}
              onClick={() => {
                setConfirmDelete(true);
              }}
            >
              Delete Account
            </div>
          </div>

          <div className="offcanvas-footer">
            <div
              className="nrm-text-bold userName"
              onClick={() => {
                dispatch(toggleSidebarAction());
                history.push(routes.ACCOUNT);
              }}
            >
              {userInfo
                ? `${userInfo?.firstName}` +
                  " " +
                  `${userInfo.middleName ? userInfo.middleName : " "}` +
                  " " +
                  `${userInfo?.lastName}`
                : ""}
            </div>
            <div className="logoutIconAndText">
              <div
                className="nrm-text-bold logout-txt"
                onClick={(e: any) => {
                  dispatch({ type: "LOG_OUT" });
                  logout({ returnTo: window.location.origin });
                  dispatch(logoutloader());
                }}
              >
                Logout
              </div>

              <div
                className="primary borderless grey"
                onClick={(e: any) => {
                  dispatch({ type: "LOG_OUT" });
                  dispatch(logoutloader());
                  logout({ returnTo: window.location.origin });
                }}
              >
                <LogoutIcon style={{ color: "red" }} />
              </div>
            </div>
          </div>
          {/* <ToastContainer /> */}
        </div>
      )}
    </>
  );
};

export default Sidebar;
