import { useAuth0 } from "@auth0/auth0-react";
import frame2 from "assets/img/frame2.svg";

import { logoutloader } from "actions/logoutloader";
import ChargePay from "assets/img/ChargePay.png";
import ChooseSubscription from "assets/img/ChooseSubscription.svg";
import meternneedle from "assets/img/Credit Score Icon.png";
import CreateAccount from "assets/img/End of Month Icon.png";
import blackcard from "assets/img/NewCardLogo.png";
import RegisterCard from "assets/img/RegisterCard.svg";
import arrow from "assets/img/arrow.png";
import pria_small1 from "assets/img/cardarch.png";
import priatemp from "assets/img/three-phone-background-image.png";
import AppButton from "common/AppButton";
import Footer from "components/Footer";
import NavMenu from "components/NavMenu/Navmenu";
import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useDispatch } from "react-redux";

import "./Home.scss";

import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import fico from "assets/img/Gear.png";
import fi1 from "assets/img/feature-icon-1.png";
import fi2 from "assets/img/feature-icon-2.png";
import fi3 from "assets/img/feature-icon-3.png";
import fi4 from "assets/img/feature-icon-4.png";
import Spinners from "components/Spinners/Spinners";
import Vidplayer from "components/Vidplayer/Vidplayer";
import useSetPermissions from "hooks/usePermissions";
import Lottie from "lottie-web";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import "./Carousel.scss";

const slideUpAnimation = keyframes`
  0% {
    transform: translateY(25%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const AnimatedDiv = styled.div`
  animation: ${slideUpAnimation} 3.3s ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  opacity: 0;
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "90%",
  bgcolor: "background.paper",
  border: "3px solid #081149",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
const styleMob = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "3px solid #081149",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const Home: React.FC = () => {
  const { loginWithRedirect, user, isAuthenticated, error, logout } = useAuth0();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const animKeyframes = '[{"start": "a0t - 95vh", "end": "a0b"}]';

  // Parse the JSON string and extract start and end values
  const keyframes = JSON.parse(animKeyframes);
  const { start, end } = keyframes[0];

  const animationStyles = {
    "--start": start,
    "--end": end,
  };

  useEffect(() => {
    window.addEventListener("resize", handleResponsive);
  }, []);
  const [carousalMode, setCarousalMode] = useState<boolean>(
    window.innerWidth <= 500 ? true : false,
  );
  const handleResponsive = () => {
    if (window.innerWidth <= 500) {
      setCarousalMode(true);
    } else {
      setCarousalMode(false);
    }
  };

  const containerr = useRef(null);
  const containerrmob = useRef(null);

  useEffect(() => {
    Lottie.loadAnimation({
      container: containerr.current as any,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: require("./data.json"),
    });
  }, []);

  useEffect(() => {
    Lottie.loadAnimation({
      container: containerrmob.current as any,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: require("./data.json"),
    });
  }, []);

  const isLoading = useSetPermissions();

  if (isLoading && isAuthenticated) {
    return <Spinners />;
  }

  if (error) {
    return (
      <div className="error-page">
        <h3 className="error-page1">
          Oops...seems like you don&apos;t have an account. {error.message}
        </h3>

        <AppButton
          className="primary outlined"
          style={{ width: "264px" }}
          onClick={() => {
            dispatch({ type: "LOG_OUT" });
            dispatch(logoutloader());
            logout({ returnTo: window.location.origin });
          }}
        >
          Go back to Pria home
        </AppButton>
      </div>
    );
  }

  return (
    <div className="page">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={carousalMode ? styleMob : style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            whiteSpace={"normal"}
            color={"#081149"}
          >
            Why does Pria only charge $3? Would my credit score increase if Pria charged more?
          </Typography>
          <Typography id="modal-modal-description" color={"#081149"} sx={{ mt: 2 }}>
            Credit Bureaus evaluate credit utilization based on the percentage of credit used, not
            dollar amounts. Therefore, when building your credit score charging and paying off $3
            monthly is just as effective as charging and paying off $50 monthly. Plus, by charging a
            smaller dollar amount, Pria is not inadvertently utilizing credit the cardholder might
            otherwise need.
          </Typography>
          <Button onClick={handleClose} variant="text">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Close
            </Typography>
          </Button>
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={carousalMode ? styleMob : style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            color={"#081149"}
            component="h2"
            whiteSpace={"normal"}
          >
            Credit Utilization and Payment History
          </Typography>
          <div>
            <Typography id="modal-modal-description" color={"#081149"} sx={{ mt: 2 }}>
              Automating credit utilization and payment history helps build and maintain your credit
              score effortlessly, enabling credit growth without developing detrimental spending
              habits. It also allows cardholders to retire unused credit cards without the risk of
              cancellation due to non-use.
            </Typography>
          </div>
          <Button onClick={handleClose1} variant="text">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Close
            </Typography>
          </Button>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={carousalMode ? styleMob : style}>
          <Typography
            id="modal-modal-title"
            color={"#081149"}
            variant="h6"
            component="h2"
            whiteSpace={"normal"}
          >
            Impact on Credit Score
          </Typography>
          <div>
            <Typography id="modal-modal-description" color={"#081149"} sx={{ mt: 2 }}>
              As a stand-alone solution to optimizing your credit card use, Pria maximizes the
              credit score you can achieve through credit card use alone. This will make you
              eligible for the low-interest rates and credit products (i.e., Installment Loans,
              Mortgages, etc.) reserved for individuals with a strong credit score.
            </Typography>
          </div>
          <Button onClick={handleClose2} variant="text">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Close
            </Typography>
          </Button>
        </Box>
      </Modal>
      <div className="hero-section">
        <NavMenu />
        <div className="main">
          <div className="main-container">
            <div className="content">
              {/* <img className="hero-img-mobile" src={hero} alt="ocean" /> */}
              <div className="containerrmob" ref={containerrmob}></div>
              <h1 className="primary">Better Credit, Made Simple</h1>
              <p style={{ letterSpacing: 0.6, lineHeight: 1.5 }}>
                PRIA IS A PREPAID SUBSCRIPTION SERVICE
                <br /> DESIGNED TO BUILD AND PROTECT YOUR <br /> CREDIT SCORE. ONE PAYMENT. BETTER
                <br /> CREDIT. ZERO DEBT.
              </p>
              <div className="btn-homepg">
                <AppButton
                  className="primary"
                  style={{
                    width: "264px",
                    height: "55px",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "22.4px",
                  }}
                  onClick={() =>
                    loginWithRedirect({
                      screen_hint: "signup",
                    })
                  }
                >
                  Get Started
                </AppButton>
              </div>
              <img
                className="sponsors-mobile"
                src={frame2}
                alt="ocean"
                style={{ width: "228px" }}
              />
              {/* <div className='sponsors'></div> */}
              <img className="sponsors" src={frame2} alt="ocean" />
            </div>
            {/* <img className="hero-img" src={hero} alt="ocean" /> */}
            <div className="containerr" ref={containerr}></div>
          </div>
        </div>
      </div>
      {/* <section className="top-banner">
        PRIA IS A PREPAID SUBSCRIPTION SERVICE DESIGNED TO BUILD AND PROTECT YOUR CREDIT SCORE.
      </section> */}

      {carousalMode ? (
        <>
          {/* <section className="top-banner1">
            ONE PAYMENT. BETTER CREDIT. <br />
            ZERO DEBT.
          </section> */}
          <section className="top-section">
            <div className="ftr-group" style={{ paddingRight: "1rem" }}>
              <AnimatedDiv style={{ ...animationStyles, animationDelay: "0s" }}>
                <div className="ftr-3">
                  <img src={blackcard} alt="ocean" className="black-cardimg" />
                  <div className="card-txtmarg1"></div>
                  <div className="lg-text-bold" style={{ letterSpacing: 1, color: "#081149" }}>
                    How Pria Works
                  </div>
                  <div className="card-txtmarg4"></div>
                  <div className="md-text-reg" style={{ lineHeight: 1.5, color: " #081149" }}>
                    Pria places a small $3 subscription on your existing credit card which the app
                    charges and pays off monthly. We call this feature Charge & Pay.
                  </div>
                  <div className="learn-more">
                    <div className="activity-block"></div>
                    <div style={{ cursor: "pointer" }} onClick={handleOpen}>
                      Learn more.
                    </div>
                  </div>
                </div>
              </AnimatedDiv>
              <AnimatedDiv style={{ animationDelay: "0.5s" }}>
                <div className="ftr-3">
                  <div className="fico1">
                    <img src={fico} alt="ocean" className="fico-cardimg" />
                  </div>
                  <div className="card-txtmarg2"></div>
                  <div className="lg-text-bold" style={{ letterSpacing: 1, color: "#081149" }}>
                    Automation
                  </div>
                  <div className="card-txtmarg4">
                    <div className="md-text-reg" style={{ lineHeight: 1.5, color: " #081149" }}>
                      Charge & Pay automates your Credit Utilization and Payment History,
                      eliminating the risk of missed payments and the need for active credit card
                      use.
                    </div>
                    <div className="learn-more">
                      <div className="activity-block1"></div>
                      <div style={{ cursor: "pointer" }} onClick={handleOpen1}>
                        Learn more.
                      </div>
                    </div>
                  </div>
                </div>
              </AnimatedDiv>
              <AnimatedDiv style={{ animationDelay: "1s" }}>
                <div className="ftr-3">
                  <div className="credit-score">
                    <img src={meternneedle} alt="ocean" className="score-cardimg" />
                  </div>
                  <div className="card-txtmarg3"></div>
                  <div className="lg-text-bold" style={{ letterSpacing: 1, color: "#081149" }}>
                    Impact on Credit Score
                  </div>
                  <div className="card-txtmarg4"></div>
                  <div className="md-text-reg" style={{ lineHeight: 1.5, color: " #081149" }}>
                    With Pria, users can track their credit score and expect to see an improvement
                    in as little as 30 days. Credit score and report included.
                  </div>
                  <div className="learn-more">
                    <div className="activity-block"></div>
                    <div style={{ cursor: "pointer" }} onClick={handleOpen2}>
                      Learn more.
                    </div>
                  </div>
                </div>
              </AnimatedDiv>
            </div>
          </section>
        </>
      ) : (
        <>
          {" "}
          {/* <section className="top-banner1">ONE PAYMENT. BETTER CREDIT. ZERO DEBT.</section> */}
          <section className="top-section">
            <div className="ftr-group">
              <AnimatedDiv style={{ ...animationStyles, animationDelay: "0s" }}>
                <div className="ftr1">
                  <img src={blackcard} alt="ocean" className="black-cardimg" />
                  <div className="card-txtmarg1"></div>
                  <div className="lg-text-bold" style={{ letterSpacing: 1, color: "#081149" }}>
                    How Pria Works
                  </div>
                  <div className="card-txtmarg4"></div>
                  <div
                    className="md-text-reg workings"
                    style={{ lineHeight: 1.5, color: " #081149" }}
                  >
                    Pria places a small $3 subscription on your existing credit card which the app
                    charges and pays off monthly. We call this feature Charge & Pay.
                  </div>
                  <div className="learn-more">
                    <div className="activity-block"></div>
                    <div style={{ cursor: "pointer" }} onClick={handleOpen}>
                      Learn more.
                    </div>
                  </div>
                </div>
              </AnimatedDiv>
              <AnimatedDiv style={{ animationDelay: "0.5s" }}>
                <div className="ftr2">
                  <div className="fico1">
                    <img src={fico} alt="ocean" className="fico-cardimg" />
                  </div>
                  <div className="card-txtmarg2"></div>
                  <div className="lg-text-bold" style={{ letterSpacing: 1, color: "#081149" }}>
                    Automation
                  </div>
                  <div className="card-txtmarg4">
                    <div
                      className="md-text-reg workings1"
                      style={{ lineHeight: 1.5, color: " #081149" }}
                    >
                      Charge & Pay automates your Credit Utilization and Payment History,
                      eliminating the risk of missed payments and the need for active credit card
                      use.
                    </div>
                    <div className="learn-more">
                      <div className="activity-block1"></div>
                      <div style={{ cursor: "pointer" }} onClick={handleOpen1}>
                        Learn more.
                      </div>
                    </div>
                  </div>
                </div>
              </AnimatedDiv>
              <AnimatedDiv style={{ animationDelay: "1s" }}>
                <div className="ftr-3">
                  <div className="credit-score">
                    <img src={meternneedle} alt="ocean" className="score-cardimg" />
                  </div>
                  <div className="card-txtmarg3"></div>
                  <div className="lg-text-bold" style={{ letterSpacing: 1, color: "#081149" }}>
                    Impact on Credit Score
                  </div>
                  <div className="card-txtmarg4"></div>
                  <div
                    className="md-text-reg workings2"
                    style={{ lineHeight: 1.5, color: " #081149" }}
                  >
                    With Pria, users can track their credit score and expect to see an improvement
                    in as little as 30 days. Credit score and report included.
                  </div>
                  <div className="learn-more">
                    <div className="activity-block"></div>
                    <div style={{ cursor: "pointer" }} onClick={handleOpen2}>
                      Learn more.
                    </div>
                  </div>
                </div>
              </AnimatedDiv>
            </div>
          </section>
        </>
      )}
      <section className="mid-mar">
        <div className="meet-pria">
          <h4 className="small-txt " style={{ letterSpacing: 1, color: "#081149" }}>
            Meet Pria
          </h4>
          {/* <img className="meet-pria__img" src={MeetPriaImg} alt="meet pria" /> */}
          <Vidplayer />
          <div className="meet-pria__text2">
            Register 3 Credit Cards per Subscription| $36 Semi-Annually | Visa and Master Card Only
          </div>
        </div>
      </section>
      {carousalMode ? (
        <>
          <div className="ben-page">
            <div className="ben-tile">
              <h4 className="small-txt " style={{ letterSpacing: 1, color: "#081149" }}>
                Benefits
              </h4>
            </div>
          </div>
          <Carousel controls={false} indicators={true}>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <img className="ftr-imageMobile" style={{ width: 125 }} src={fi1} alt="ocean" />
                  <div
                    className="lg-text-bold"
                    style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                  >
                    Automate Building<br></br> Your Credit Score
                  </div>
                  <div className="md-text-reg" style={{ color: "#081149" }}>
                    Pria builds your
                    <br /> credit score even if
                    <br /> you are not actively
                    <br /> using your credit card.
                  </div>
                </div>
              </section>
            </Carousel.Item>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <img className="ftr-imageMobile" style={{ width: 125 }} src={fi2} alt="ocean" />
                  <div
                    className="lg-text-bold"
                    style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                  >
                    Avoid Credit<br></br> Card Debt
                  </div>
                  <div className="md-text-reg" style={{ color: "#081149" }}>
                    As a Prepaid Service,
                    <br /> Pria eliminates the risk <br /> of missed payments.
                    <br /> Better Credit, zero debt.
                  </div>
                </div>
              </section>
            </Carousel.Item>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <img className="ftr-imageMobile" style={{ width: 125 }} src={fi3} alt="ocean" />
                  <div
                    className="lg-text-bold"
                    style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                  >
                    Protect Your<br></br> Credit History
                  </div>
                  <div className="md-text-reg" style={{ color: "#081149" }}>
                    Length of Credit History is
                    <br /> 15% of your Credit Score. Pria
                    <br /> prevents credit cards from
                    <br /> being cancelled for non-use.
                  </div>
                </div>
              </section>
            </Carousel.Item>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <img className="ftr-imageMobile" style={{ width: 125 }} src={fi4} alt="ocean" />
                  <div
                    className="lg-text-bold"
                    style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                  >
                    Make One Payment,<br></br> Not Several
                  </div>
                  <div className="md-text-reg" style={{ color: "#081149" }}>
                    Pria does not require <br /> monthly payments.
                    <br /> Pria operates on a <br />
                    single payment.
                  </div>
                </div>
              </section>
            </Carousel.Item>
          </Carousel>
          <div className="spacebtn">
            <div className="quote">
              <p>At Pria we believe using a Credit Card should be an option, not an Obligation. </p>
              <p>We hope you enjoy! </p>
            </div>
          </div>
          <section className="d-flex flex-column align-items-center getting-startedmar">
            <h4 className="small-txt">Getting Started</h4>
          </section>
          <Carousel controls={false} indicators={true}>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <div className="illustrations">
                    <img style={{ maxWidth: 140, height: 140 }} src={CreateAccount} alt="" />
                    <img id="arrow" src={arrow} alt="arrow" />
                    <Link
                      to="/faq#5"
                      style={{ textDecoration: "none", color: "#081149", cursor: "pointer" }}
                    >
                      <h5>Set Statement Closing Date</h5>
                      <div style={{ fontSize: "20px", fontWeight: "500px" }}>(See How)</div>
                    </Link>
                  </div>
                </div>
              </section>
            </Carousel.Item>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <div className="illustrations">
                    <img style={{ maxWidth: 150, marginBottom: 60 }} src={RegisterCard} alt="" />
                    <img id="arrow" src={arrow} alt="arrow" />
                    <h5>Register your card</h5>
                  </div>
                </div>
              </section>
            </Carousel.Item>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <div className="illustrations">
                    <img style={{ maxWidth: 150, height: 180 }} src={ChooseSubscription} alt="" />
                    <img id="arrow" src={arrow} alt="arrow" />
                    <h5 style={{ textAlign: "left" }}>Choose a subscription</h5>
                  </div>
                </div>
              </section>
            </Carousel.Item>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <div className="illustrations">
                    <img style={{ maxWidth: 180, marginBottom: 64 }} src={ChargePay} alt="" />
                    <img id="arrow" src={arrow} alt="arrow" />
                    <h5>Charge & Pay</h5>
                  </div>
                </div>
              </section>
            </Carousel.Item>
          </Carousel>
        </>
      ) : (
        <>
          {/* return ( */}
          <section>
            <div className="ben-page">
              <div className="ben-tile font-hai" style={{ letterSpacing: 1 }}>
                <h4>Benefits</h4>
              </div>
            </div>
          </section>
          <section>
            <div className="ftr-group">
              <div className="ftr">
                <img className="ftr-image" src={fi1} alt="ocean" />
                <div
                  className="lg-text-bold"
                  style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                >
                  Automate Building<br></br> Your Credit Score
                </div>
                <div className="md-text-reg" style={{ color: "#081149" }}>
                  Pria builds your
                  <br /> credit score even if
                  <br /> you are not actively
                  <br /> using your credit card.
                </div>
              </div>
              <div className="ftr">
                <img className="ftr-image" src={fi2} alt="ocean" />
                <div
                  className="lg-text-bold"
                  style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                >
                  Avoid Credit<br></br> Card Debt
                </div>
                <div className="md-text-reg" style={{ color: "#081149" }}>
                  As a Prepaid Service,
                  <br /> Pria eliminates the risk <br /> of missed payments.
                  <br /> Better Credit, zero debt.
                </div>
              </div>
              <div className="ftr">
                <img className="ftr-image" src={fi3} alt="ocean" />
                <div
                  className="lg-text-bold"
                  style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                >
                  Protect Your<br></br> Credit History
                </div>
                <div className="md-text-reg" style={{ color: "#081149" }}>
                  Length of Credit History is
                  <br /> 15% of your Credit Score. Pria
                  <br /> prevents credit cards from
                  <br /> being cancelled for non-use.
                </div>
              </div>
              <div className="ftr">
                <img className="ftr-image" src={fi4} alt="ocean" />
                <div
                  className="lg-text-bold"
                  style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                >
                  Make One Payment,<br></br> Not Several
                </div>
                <div className="md-text-reg" style={{ color: "#081149" }}>
                  Pria does not require <br /> monthly payments.
                  <br /> Pria operates on a <br />
                  single payment.
                </div>
              </div>
            </div>
          </section>
          <section style={{ display: "inline-block" }}>
            <div className="spacebtn">
              <div className="quote">
                <p>
                  At Pria we believe using a Credit Card should be an option, not an Obligation. We
                  hope you enjoy!
                </p>
              </div>
            </div>
          </section>
          <section className="d-flex flex-column align-items-center">
            <h3>Getting Started</h3>
            <div className="ftr-group">
              <div className="ftr">
                <Link
                  to="/faq#5"
                  style={{ textDecoration: "none", color: "#081149", cursor: "pointer" }}
                >
                  <div className="illustrations1">
                    <img style={{ maxWidth: 140, height: 140 }} src={CreateAccount} alt="" />
                    <h5>Set Statement Closing Date</h5>
                    <div style={{ fontSize: "20px", fontWeight: "500px" }}>(See How)</div>
                  </div>
                </Link>
              </div>

              <img id="arrow" src={arrow} alt="arrow" />

              <div className="ftr">
                <div className="illustrations">
                  <img style={{ maxWidth: 150, marginBottom: 60 }} src={RegisterCard} alt="" />
                  <h5>Register your card</h5>
                </div>
              </div>

              <img id="arrow" src={arrow} alt="arrow" />

              <div className="ftr">
                <div className="illustrations">
                  <img style={{ maxWidth: 150, height: 180 }} src={ChooseSubscription} alt="" />
                  <h5 style={{ textAlign: "left" }}>Choose a subscription</h5>
                </div>
              </div>

              <img id="arrow" src={arrow} alt="arrow" />

              <div className="ftr">
                <div className="illustrations">
                  <img style={{ maxWidth: 180, marginBottom: 64 }} src={ChargePay} alt="" />
                  <h5>Charge & Pay</h5>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {/* <Carousel controls={true} indicators={false}>
        <Carousel.Item interval={3000}>
          <img className="carousel-img" src={priahalftemp} />
          <Carousel.Caption>
            <div className="carousel-content">
              <div className="carousel-text">
                <h3>How does Pria work?</h3>
                <div className="lg-text-reg mt-4">
                  Pria works by creating a small automated expense that is charged and paid off by
                  the app at the end of each month. We call this feature Charge&Pay.
                </div>
                <div className="carimg mt-4">
                  <img src={how} alt="ocean" />
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="carousel-img" src={priahalftemp} />
          <Carousel.Caption>
            <div className="carousel-content">
              <div className="carousel-text">
                <h3>Why does Pria work?</h3>
                <div className="lg-text-reg mt-4">
                  The Pria Charge&Pay feature works by addressing each component of a card holders
                  credit score.
                </div>
                <div className="carimg mt-4">
                  <img src={fico} alt="ocean" />
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="carousel-img" src={priahalftemp} />
          <Carousel.Caption>
            <div className="carousel-content">
              <div className="carousel-text">
                <h3>Who is Pria for?</h3>
                <div className="lg-text-reg mt-4">
                  Pria is for anyone who wants to build their credit score with minimal risk, or
                  preserve their credit history with minimal effort.
                </div>
                <div className="nrm-text-reg mt-4">
                  <p> - Perfect for young adults managing their first credit card.</p>- Perfect for
                  mature adults with old or seldom used credit cards.
                </div>
                <div className="carimg mt-4">
                  <img src={creditscore} alt="ocean" />
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel> */}
      {/* <div className="faqs">
        <h3>Frequently Asked Questions</h3>

        {FAQ.map((faq: any, key: number) => (
          <FAQComp faq={faq} index={key} key={key} faqOpen={faqOpen[key]} />
        ))}
      </div> */}
      <div className="pria-financial-text">
        PRIA <img className="pria-logo" src={pria_small1} alt="" />
        FINANCIAL.COM
      </div>
      <div className="pria-financial-text-2">
        <div className="sm-text text-center">
          REGISTER $3 CREDIT CARDS PER SUBSCRIPTION | $36 SEMI-ANNUALLY | VISA & MASTERCARD ONLY
        </div>
      </div>
      <div className="pria-financial-mobile-image" style={{ textAlign: "center" }}>
        <img src={priatemp} alt="LOGO" className="pria-temp-image" />
      </div>
      <div className="end-banner">
        <h4>Get started with Pria today </h4>
        <AppButton
          className="outlined primary"
          onClick={() =>
            loginWithRedirect({
              screen_hint: "signup",
            })
          }
        >
          <div className="md-text-bold"> Sign Up</div>
        </AppButton>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
