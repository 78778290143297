import AdminDashboard from "components/AdminDashboard";
import Hamburger from "components/Hamburger/Hamburger";
import AppNavbar from "components/Navbar";
import * as routes from "constants/routes";
import { usePermission } from "Context/AdminContext";
import useFetchUser from "hooks/useFetchUser";
import useGetAccessToken from "hooks/useGetAccessToken";
import { useEffect, useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { FiArrowDownRight } from "react-icons/fi";
import { IoMdDocument as IoDocument } from "react-icons/io";
import { useMutation } from "react-query";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { handleError } from "utils/handleError";
import { handleSuccess } from "utils/handleSuccess";
import { postPayOut, postPayIn, postSubscriptionPayIn } from "./../../api/ForcePayInPayOut";
import Logs from "./Logs/Logs";
import { sidebarItemsAdmin } from "./sidebaritems.config";
import "./styles.scss";
import Transactions from "./Transactions/Transactions";
import UsersAdmin from "./Users/UsersAdmin";
import TransectionSummary from "./TransectionSummary/TransectionSummary";
import ClientFeedback from "components/ClientFeedback/ClientFeedback";
import ClientMessage from "components/ClientMessages/ClientMessages";

// import AppNavbar from "components/Navbar/AppNavbar";

const AdminPortal = () => {
  const { hasPrivilege } = usePermission();
  const history = useHistory();

  useEffect(() => {
    window.addEventListener("resize", handleResponsive);
  }, []);

  const [mobileView, setMobileView] = useState<boolean>(window.innerWidth < 992 ? true : false);

  const handleResponsive = () => {
    if (window.innerWidth < 992) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useFetchUser();

  const { mutate: payInWithQuery } = useMutation(postPayIn, {
    onSuccess: async (res) => {
      if (res?.status >= 200 && res?.status < 300) {
        handleSuccess("Transactions successfully payed in", () => {
          history.go(0);
        });

        // history.go(0);
      } else handleError("Pay In failed");
    },
  });
  const { mutate: payOutWithQuery } = useMutation(postPayOut, {
    onSuccess: async (res) => {
      if (res?.status >= 200 && res?.status < 300) {
        handleSuccess("Transactions successfully payed out", () => {
          history.go(0);
        });

        // history.go(0);
      } else handleError("Pay Out failed");
    },
  });
  const { mutate: payInSubscription } = useMutation(postSubscriptionPayIn, {
    onSuccess: async (res) => {
      if (res?.status >= 200 && res?.status < 300) {
        handleSuccess("Subscriptions successfully payed in", () => {
          history.go(0);
        });

        // history.go(0);
      } else handleError("Pay In failed");
    },
  });

  const token = useGetAccessToken();

  const handlePayin = async () => {
    const payIn = confirm("System will forcefully try to payin");

    token && payIn && payInWithQuery(token);
  };

  const handlePayout = async () => {
    const payOut = confirm("System will forcefully try to payOut");
    token && payOut && payOutWithQuery(token);
  };

  const handleSubscriptionPayIn = () => {
    const payIn = confirm("System will forcefully try to payin");
    token && payIn && payInSubscription(token);
  };

  return (
    <>
      {mobileView ? (
        <>
          <div className="admin-navbar admin-navbar-brand">
            <h4>PRIA</h4>
          </div>
          <Hamburger />
        </>
      ) : (
        <AppNavbar />
      )}
      {/* <AppNavbar /> */}
      {mobileView ? <Hamburger /> : null}{" "}
      <div className="adminportal-page">
        {!mobileView ? (
          <div className="sidebar-admin">
            {sidebarItemsAdmin.map((item) => {
              return (
                <div key={item.key} className="admin-sidbar-item">
                  {hasPrivilege(item.privilege) && (
                    <Link to={item.path} style={{ textDecoration: "none", color: "#081149" }}>
                      {item.imgIcon ? <img src={item.imgIcon} /> : <item.icon />} {item.title}
                    </Link>
                  )}
                </div>
              );
            })}

            <span className="admin-sidbar-item" style={{ cursor: "pointer" }} onClick={handlePayin}>
              <FiArrowUpRight color="green" /> Transaction Pay In
            </span>
            <span
              className="admin-sidbar-item"
              style={{ cursor: "pointer" }}
              onClick={handlePayout}
            >
              <FiArrowDownRight color="red" />
              Payout
            </span>
            <span
              className="admin-sidbar-item"
              style={{ cursor: "pointer" }}
              onClick={handleSubscriptionPayIn}
            >
              <IoDocument color="green" />
              Subscription Pay In
            </span>
          </div>
        ) : null}

        <div className="main-container-admin">
          <Switch>
            <Route path={routes.USERS} component={UsersAdmin}></Route>
            <Route path={routes.CARDINFO} component={() => <>Card</>}></Route>
            <Route path={routes.LOG} component={Logs}></Route>
            <Route path={routes.TRANSACTION} component={Transactions}></Route>
            <Route path={routes.TRANSACTIONSUMMARY} component={TransectionSummary}></Route>
            <Route path={routes.CLIENT_FEEDBACK} component={ClientFeedback}></Route>
            <Route path={routes.CLIENT_MESSAGE} component={ClientMessage}></Route>
            <Route path={"/"} component={AdminDashboard}></Route>
          </Switch>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default AdminPortal;
