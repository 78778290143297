import { useAuth0 } from "@auth0/auth0-react";
import { subscribeProduct, subscribeProductResetAction } from "actions";
import AppButton from "common/AppButton";
import { BackIcon } from "common/AppIcons";
import AppInput from "common/AppInput";
import AppSelect from "common/AppSelect";
import { CardBrand } from "constants/cards";
import * as routes from "constants/routes";
import { useFormik } from "formik";
import useAdministrativeAreas from "hooks/useAdministrativeAreas";
import useFetchUserCards from "hooks/useFetchCards";
import { useFetchUserCtx } from "hooks/useFetchUser";
import { isEmpty } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { postCardAPI } from "services/cards";
import { RootState } from "store";
import { ICard } from "types";
import { IProduct } from "types/IProduct";
import getCardBrand from "utils/cardType";
import { handleError } from "utils/handleError";
import { handleSuccess } from "utils/handleSuccess";
import { usePostalCodeState } from "utils/zipCode";
import * as Yup from "yup";
import SuccessScreen from "./SuccessScreen";
import "./checkout.scss";

const CheckoutPage = (props: any) => {
  const hide = props?.hide;
  const refetchUser = useFetchUserCtx();
  const selectedProduct: IProduct = useSelector(
    (state: RootState) => state.products.selectedProduct,
  );
  const subscribedProduct = useSelector((state: RootState) => state.products.subscribedProduct);
  const cards = useSelector((state: RootState) => state.cards.cards);
  //let defaultCard = cards?.find((card: ICard) => card.isDefault === 'true');
  const [defaultCard, setdefaultCard] = useState(
    cards?.find((card: ICard) => card.isDefault === true) || null,
  );

  const [fundingCard, setFundingCard] = useState<ICard | null>(null);

  const productSubscribeSuccess = useSelector(
    (state: RootState) => state.products.productSubscribeSuccess,
  );
  const userInfo = useSelector((state: RootState) => state.user.user);
  const subscriptionCard = userInfo.subscription.card;
  console.log("subscriptionCard", subscriptionCard);

  const cardLoading = useSelector((state: RootState) => state.cards.loading);
  const productSubscribeError = useSelector((state: RootState) => state.products.error);
  const subscriptionLoading = useSelector((state: RootState) => state.products.loading);

  const { user, getAccessTokenSilently } = useAuth0();

  const [useSameInfo, setUseSameInfo] = useState(false);
  const [newCard, setNewCard] = useState(true);
  const [policyValue, setPolicyValue] = useState(false);
  // const [existingCard, setExistingCard] = useState(false);

  const subscribeSuccessData = useSelector((state: RootState) => state.products.responseData);
  const [successScreen, setSuccessScreen] = useState(!isEmpty(subscribedProduct));

  const [states] = useAdministrativeAreas();

  const dispatch = useDispatch();

  const refetchCards = useFetchUserCards(false);

  const formik = useFormik({
    initialValues: {
      name: user?.name || "",
      number: "",
      expirationDate: "",
      cvc: "",
      address: {
        address1: "",
        address2: "",
        locality: "",
        administrativeArea: states?.[0]?.value,
        country: "US",
        postalCode: "",
      },
      brand: "",
      phone: "",
    },
    validationSchema: newCard
      ? Yup.object({
          number: Yup.string().required("Card Number is required"),
          expirationDate: Yup.string()
            .matches(/^(0[1-9]|1[0-2])\/[0-9]{2}$/, "Invalid date format")
            .test("validity", "Invalid Date", (value: any) => {
              if (!value) {
                return false;
              }
              const month = parseInt(value.slice(0, 2), 10);
              const year = parseInt(value.slice(-2), 10) + 2000;
              const currentYear = new Date().getFullYear();
              const currentMonth = new Date().getMonth() + 1;
              if (year < currentYear || (year === currentYear && month < currentMonth)) {
                return false; // expiration date is in the past
              }
              if (month < 1 || month > 12) {
                return false; // invalid month number
              }
              return true;
            })
            .required("Expiry Date is required"),
          cvc: Yup.string().required("CVC is required"),
          address: Yup.object().shape({
            address1: Yup.string().required("Street 1 is required"),
            address2: Yup.string().nullable(true),
            locality: Yup.string().required("City is required"),
            administrativeArea: Yup.string(),
            country: Yup.string().nullable(true),
            postalCode: Yup.string()
              .matches(/^\d{5}([-]\d{4})?$/, "Invalid postal code")
              .required("Postal Code is required"),
          }),
        })
      : "",
    onSubmit: async (values: any) => {
      const token: string = await getAccessTokenSilently();
      if (user?.sub) {
        if (newCard) {
          let expYear = `20${values?.expirationDate?.split("/")[1]}`;
          let expMonth = values?.expirationDate?.split("/")[0];
          const requestBody: ICard = {
            number: values.number.replace(/\s/g, ""),
            expirationMonth: parseInt(expMonth, 10),
            expirationYear: parseInt(expYear, 10),
            cvc: parseInt(values.cvc as string, 10),
            address: { ...values.address, administrativeArea: values.address.administrativeArea },
            isDefault: true,
            brand: CardBrand?.[values?.brand] || "",
          };

          postCardAPI(user?.sub, requestBody, token)
            .then((response: any) => {
              if (
                response &&
                response.data &&
                response.data.httpCode >= 200 &&
                response.data.httpCode <= 205
              ) {
                // dispatch(subscribeProduct(userId, selectedProduct.id, token, response.data.data));

                dispatch(
                  subscribeProduct(
                    userInfo.subscription.id,
                    Number(selectedProduct.id),
                    Number(response.data.data.id),
                  ),
                );
                refetchCards();
              }
            })
            .catch((error) => {
              console.log(error);
              handleError(error?.response?.data?.message);
            });
          //dispatch(postCard(user?.sub, requestBody, token));
        } else {
          if (fundingCard) {
            // dispatch(subscribeProduct(userId, selectedProduct.id, token, fundingCard.id));
            fundingCard.id &&
              dispatch(
                subscribeProduct(
                  userInfo.subscription.id,
                  Number(selectedProduct.id),
                  Number(fundingCard.id),
                ),
              );

            refetchCards();
          } else {
            // dispatch(subscribeProduct(userId, selectedProduct.id, token, defaultCard.id));
          }
        }

        //selectedProduct && dispatch(subscribeProduct(user.sub, selectedProduct.id));
      }
    },
  });
  const history = useHistory();

  useEffect(() => {
    if (useSameInfo) {
      formik.setFieldValue("address.address1", userInfo?.address?.address1);
      formik.setFieldValue("address.address2", userInfo?.address?.address2);
      formik.setFieldValue("address.locality", userInfo?.address?.locality);
      formik.setFieldValue("address.administrativeArea", userInfo?.address?.administrativeArea);
      formik.setFieldValue("address.country", userInfo?.address?.country);
      formik.setFieldValue("address.postalCode", userInfo?.address?.postalCode);
      formik.setFieldValue("phone", userInfo?.phone);
    } else {
      formik.setFieldValue("address.address1", "");
      formik.setFieldValue("address.address2", "");
      formik.setFieldValue("address.locality", "");
      formik.setFieldValue("address.administrativeArea", "");
      formik.setFieldValue("address.country", "US");
      formik.setFieldValue("address.postalCode", "");
      formik.setFieldValue("phone", "");
    }
  }, [useSameInfo, defaultCard]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (productSubscribeSuccess) {
      refetchUser();
      handleSuccess("Updated successfully");
      setSuccessScreen(true);
      dispatch(subscribeProductResetAction());
      props.setChangedFunding(false);
    }
  }, [productSubscribeSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (productSubscribeError?.length > 0) {
      // handleError('Error');
      dispatch(subscribeProductResetAction());
    }
  }, [productSubscribeError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      subscribeSuccessData &&
      subscribeSuccessData.stripe_js &&
      subscribeSuccessData.stripe_js.length > 0
    ) {
      // console.log("sub data", subscribeSuccessData.stripe_js);

      window.open(subscribeSuccessData?.stripe_js, "_blank")?.focus();
    }
  }, [subscribeSuccessData]);

  const selectCardOptions = [
    {
      value: "new",
      label: "New Credit/Debit card",
    },
    {
      value: "existing",
      label: "Choose existing card",
    },
  ];

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      console.log("User pressed: ", event.key);

      if (event.key === "Enter") {
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
  }, []);

  const {
    handleAdministrativeAreaChange: handleStateChange,
    handlePostalCodeChange,
  } = usePostalCodeState(formik);

  const showSuccess = false;

  return (
    <div className="checkout-page">
      <div className="container">
        {showSuccess ? (
          <div>
            {" "}
            <SuccessScreen />
          </div>
        ) : (
          <>
            {!hide && (
              <div>
                <div className="title">
                  {" "}
                  <h6>Checkout</h6>
                </div>

                <div className="section">
                  <div className="md-text-bold">Order Summary</div>
                  <div className="card">
                    <div className="col-sm-9">
                      <h5 style={{ textTransform: "capitalize" }}> {selectedProduct?.name} </h5>
                      <div className="nrm-text-reg">
                        {`$${selectedProduct?.price / (100 * selectedProduct?.intervalCount)}/${
                          selectedProduct?.interval
                        }`}
                      </div>
                    </div>
                    <div className="order-alignment">
                      <div className="align-checkout-amts">
                        <h6>Amount:&ensp;</h6>
                        <h6>{`$${Math.round((selectedProduct?.price * 100) / 100) || ""}`}</h6>
                      </div>
                      <div className="align-checkout-amts">
                        <h6>Tax:&ensp;</h6>
                        <h6>{`${selectedProduct?.taxPercentage || ""}%`}</h6>
                      </div>
                      <div className="align-checkout-amts">
                        <h6>Total:&ensp;</h6>
                        <h6>
                          {`$${
                            selectedProduct.price * (1 + selectedProduct?.taxPercentage / 100) || ""
                          }`}
                        </h6>
                      </div>
                      <div>(Including Taxes)</div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="section">
              <div className="md-text-bold">Payment Information</div>
              <div className="card">
                <Form className="col-sm-12">
                  <Form.Group as={Row}>
                    <Form.Label>Choose Payment method</Form.Label>
                    <Col sm="12">
                      <AppSelect
                        options={selectCardOptions}
                        size="sm"
                        style={{ alignSelf: "flex-end", WebkitAppearance: "menulist" }}
                        defaultValue="new"
                        onChange={(e: any) => {
                          setNewCard(e.target.value === "new" ? true : false);
                          setFundingCard(null);
                          // setFundingCard((prev: ICard) => (e.target.value === 'new' ? null : prev));
                          formik.resetForm();
                        }}
                      />
                    </Col>
                  </Form.Group>
                  {!newCard ? (
                    <>
                      <Form.Group as={Col}>
                        {cards.map((card: ICard, index: number) => (
                          <div key={index} className="checkbox">
                            <Form.Check
                              id="defaultCard"
                              checked={
                                fundingCard
                                  ? fundingCard?.id === card.id
                                  : defaultCard
                                  ? defaultCard.id === card.id
                                  : false
                              }
                              onChange={(e: any) => {
                                setFundingCard(card);
                                formik.setFieldValue("number", `****${card?.lastFour}`);
                                formik.setFieldValue("cvc", `***`);
                                formik.setFieldValue(
                                  "expirationDate",
                                  `${card?.expirationYear.toString()}/${card?.expirationMonth
                                    .toString()
                                    .padStart(2, "0")}`,
                                );
                              }}
                            />
                            <Form.Check.Label>{`************${card.lastFour}`} </Form.Check.Label>
                          </div>
                        ))}
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <Form.Group as={Row}>
                        <Form.Label>Name on Card</Form.Label>
                        <Col sm="12">
                          <AppInput
                            name="name"
                            placeholder="Eg. John Doe"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label>Card Number</Form.Label>
                        <Col sm="12">
                          <AppInput
                            placeholder="Your 16 digit card number"
                            name="number"
                            value={formik.values.number}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              let value = e.target.value;
                              const brand = getCardBrand(value);
                              formik.setFieldValue("brand", brand);
                              value = value
                                .replace(/[^\dA-Z]/g, "")
                                .replace(/(.{4})/g, "$1 ")
                                .trim();
                              e.target.value = value;
                              return formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            pattern="[0-9\s]{13,19}"
                            maxLength={19}
                            autoComplete="cc-number"
                            inputMode="numeric"
                            type="tel"
                            // onKeyDown={(e: any) => {
                            //   formik.setFieldValue(
                            //     "number",
                            //     `${e.target.value
                            //       .replace(/[^\dA-Z]/g, "")
                            //       .replace(/(.{4})/g, "$1 ")
                            //       .trim()}`,
                            //   );
                            // }}
                          />
                          {formik.errors.number && formik.touched.number && (
                            <div className="invalid-feedback-text">{formik.errors.number}</div>
                          )}
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm="6">
                          <Form.Label>Expiry Date</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppInput
                              id="valid"
                              type="text"
                              name="expirationDate"
                              maxLength={5}
                              value={formik.values.expirationDate}
                              onChange={(e: any) => {
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                              onKeyDown={(e: any) => {
                                if (e.key !== "Backspace" && e.key !== "/") {
                                  if (e.target.value.length === 2) {
                                    formik.setFieldValue(
                                      "expirationDate",
                                      `${e.target.value.replace(/(.{2})/g, "$1/").trim()}`,
                                    );
                                  }
                                }
                              }}
                            />

                            {formik.errors.expirationDate && formik.touched.expirationDate && (
                              <div className="invalid-feedback-text">
                                {formik.errors.expirationDate}
                              </div>
                            )}
                          </Col>
                        </Col>
                        <Col sm="6">
                          <Form.Label>CVC</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppInput
                              id="cvc"
                              name="cvc"
                              type="tel"
                              inputMode="numeric"
                              maxLength={4}
                              placeholder="_ _ _ "
                              value={formik.values.cvc}
                              onChange={(e: any) => {
                                let value = e.target.value;
                                value = value.replace(/[^0-9/]/g, "");
                                e.target.value = value;
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.cvc && formik.touched.cvc && (
                              <div className="invalid-feedback-text">{formik.errors.cvc}</div>
                            )}
                          </Col>
                        </Col>
                      </Form.Group>
                      <div className="form-row align-items-center">
                        <Form.Check
                          id="sameInfo"
                          checked={useSameInfo}
                          onChange={() => setUseSameInfo((prev) => !prev)}
                          style={{ width: "fit-content" }}
                        />
                        <Form.Check.Label>Use same as profile address</Form.Check.Label>
                      </div>
                      <Form.Group as={Row}>
                        <Col sm="6">
                          <Form.Label>Street 1</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppInput
                              id="address1"
                              type="text"
                              name="address.address1"
                              value={formik.values.address?.address1}
                              onChange={formik.handleChange}
                              placeholder="Eg. 123 Accacia Ave."
                            />
                            {formik.errors.address?.address1 &&
                              formik.touched.address?.address1 && (
                                <div className="invalid-feedback-text">
                                  {formik.errors.address?.address1}
                                </div>
                              )}
                          </Col>
                        </Col>
                        <Col sm="6">
                          <Form.Label>Street 2</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppInput
                              id="address2"
                              type="text"
                              name="address.address2"
                              value={formik.values.address?.address2}
                              onChange={formik.handleChange}
                            />
                          </Col>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm="6">
                          <Form.Label>City</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppInput
                              id="city"
                              type="text"
                              name="address.locality"
                              value={formik.values.address?.locality}
                              onChange={formik.handleChange}
                              placeholder="Eg. Manhattan"
                            />
                            {formik.errors.address?.locality &&
                              formik.touched.address?.locality && (
                                <div className="invalid-feedback-text">
                                  {formik.errors.address?.locality}
                                </div>
                              )}
                          </Col>
                        </Col>
                        <Col sm="6">
                          <Form.Label>State/Province</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppSelect
                              options={states}
                              size="sm"
                              style={{ WebkitAppearance: "menulist", maxWidth: "100%" }}
                              name="address.administrativeArea"
                              value={formik.values?.address?.administrativeArea}
                              onChange={handleStateChange}
                              onBlur={formik.handleBlur}
                              placeholder="Select State"
                            />
                          </Col>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm="6">
                          <Form.Label>Country</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppInput
                              id="line1"
                              type="text"
                              readOnly={true}
                              name="address.country"
                              value={formik.values.address?.country}
                              onChange={formik.handleChange}
                              placeholder="Select Country"
                            />
                          </Col>
                        </Col>
                        <Col sm="6">
                          <Form.Label>Zip Code</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppInput
                              id="line1"
                              type="text"
                              name="address.postalCode"
                              autoComplete="off"
                              maxLength={5}
                              value={formik.values.address?.postalCode}
                              onChange={handlePostalCodeChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.address?.postalCode &&
                              formik.touched.address?.postalCode && (
                                <div className="invalid-feedback-text">
                                  {formik.errors.address?.postalCode}
                                </div>
                              )}
                            {formik.errors.address?.administrativeArea &&
                              formik.touched.address?.administrativeArea && (
                                <div className="invalid-feedback-text">
                                  {formik.errors.address?.administrativeArea}
                                </div>
                              )}
                          </Col>
                        </Col>
                      </Form.Group>
                      {!hide && (
                        <Form.Group as={Row}>
                          <Col sm="6">
                            <Form.Label>Phone</Form.Label>
                            <Col sm="12" className="p-0">
                              <AppInput
                                id="line1"
                                type="text"
                                name="phone"
                                value={formik.values.phone}
                                onChange={(e: any) => {
                                  const re = /^[0-9\b]+$/;
                                  if (e.target.value === "" || re.test(e.target.value)) {
                                    formik.handleChange(e);
                                  }
                                }}
                                placeholder="Eg. 1 (234) 567-890"
                              />
                            </Col>
                          </Col>
                        </Form.Group>
                      )}
                    </>
                  )}
                  <Form.Group>
                    <div
                      className="sm-text-reg"
                      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                    >
                      <Form.Check
                        onChange={(e: any) => {
                          setPolicyValue(e.target.checked);
                        }}
                      />
                      By choosing an existing card I understand I must pay the Subscription Fee
                      which will be reflected on my next billing statement
                    </div>
                  </Form.Group>
                  <div className="sm-text-reg">
                    By submitting this form, you agree to our terms of service and privacy policy.
                  </div>

                  <div className="d-flex flex-row justify-content-between">
                    <AppButton
                      className="secondary borderless"
                      style={{ textDecoration: "underline" }}
                      onClick={() => history.push(routes.SUBSCRIPTION)}
                    >
                      <BackIcon />
                      Back to plans
                    </AppButton>
                    <AppButton
                      className="primary"
                      onClick={formik.handleSubmit}
                      isLoading={subscriptionLoading || cardLoading}
                      disabled={
                        newCard ? subscriptionLoading || cardLoading : policyValue === false
                      }
                    >
                      Continue
                    </AppButton>
                  </div>
                </Form>
              </div>
            </div>
          </>
        )}

        <ToastContainer />
      </div>
    </div>
  );
};

export default CheckoutPage;
