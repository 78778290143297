import axios from "axios";
import React, { useEffect, useRef } from "react";

const API_KEY = "5a10e0d7-1dc8-4325-929f-d0d131990527&ocf=2039";
const LOGO_URL = "https://pria-dev-526485.s3.us-west-1.amazonaws.com/PRIA.svg&oct=081149";
const BRAND_COLOR = "0a1149";

const CRS: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const id =
    document.location.search.length > 0
      ? document.location.search.split("&")[0].split("=")[1]
      : "048741d2-3dcf-4892-8fb0-99930a540cc1";

  useEffect(() => {
    console.log("ID: ", id);
    console.log("Loading Message Listener...");
    window.addEventListener("message", receiveMessage, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function receiveMessage(event: any) {
    if (event && event.source && event.data) {
      console.log("date", event.data);

      // You only need to implement the types that are important/relevant to your use case.
      if (event.data.type === "AUTH_REQUIRED") {
        //The iframe clident will post this message when a valid preauth token does not exist.
        //Posting a proper message with a preauth token in response allows the client to continue seemlessly.
        //console.log("Auth Required Event Received");
        const es = event.source;
        //this code uses a test endpoint on the server to provide a preauth-token for any user ID without the usual hurdles.
        //This is ONLY for testing and does not exist in the production environment.
        axios
          .get(`https://efx-dev.stitchcredit.com/api/test/preauth-token/${id}`)
          .then(function (res) {
            console.log(res);

            // const token = JSON.parse(this.responseText).token;
            // console.log("Status: ", this.status, ", token: ", token);
            // es.postMessage({ type: "PREAUTH", token: this.status == 200 ? token : null }, "*");
          });
      } else if (event.data.type === "REG_STARTED") {
        const es = event.source;
        //only valid for full web implementation, Direct API already creates the customer, so this will never happen in those instances
        // (new Date().valueOf()) - is used to generate a new email ID on the fly for testing purposes
        es.postMessage(
          {
            type: "REG",
            data: {
              fname: "Gertrude",
              lname: "Harkenreadeo",
              email: `test+${new Date().valueOf()}@test.com`,
            },
          },
          "*",
        );
      } else if (event.data.type === "IDENTITY_STARTED") {
        const es = event.source;
        //You could use this function to pre-populate the given fields.  DoB and SSN will never be prepopulated as it violates compliance
        // new Date().valueOf().toString() - generates a unique number for street2 to ensure each run goes through the full identity process, remove to test sequential sign up of the same user
        es.postMessage(
          {
            type: "IDENTITY",
            data: {
              street1: "305 Linden Av",
              street2: new Date().valueOf().toString(),
              city: "Atlanta",
              state: "GA",
              zip: "30316",
              mobile: "0000000000",
            },
          },
          "*",
        );
      } else if (event.data.type === "LOGIN_SUCCESSFUL") {
        console.log("User succesfully logged in");
      } else if (event.data.type === "LOGIN_FAILED") {
        //if you see this message more than a few times in a row, it's likely an issue
        //typically this will only occur for full web implementations, not Direct API
        console.log("User login failed");
      } else if (event.data.type === "USER_ENROLLED") {
        //User successfully completed identity and has been enrolled for consumer data
        console.log("User enrollment successful");
      } else if (event.data.type === "IDENTITY_FAILED") {
        //Identity process failed, user is likely "stuck" as they cannot continue
        console.log("User identity failure");
      } else if (event.data.type === "SERVICE_FAILURE") {
        //Identity process failed most likely due to a service outage, but the user is stuck as they cannot continue without passing identity
        console.log("Identity service failure");
      }
    }
  }

  return (
    <>
      <style>
        {`body { background: #F9FAFB; }
    #sc-iframe { border:none;overflow:hidden;padding:0px;margin:0px;
    width:100%;height:100vh;z-index:1;position:absolute;left:0;top:0; }`}
      </style>
      <iframe
        ref={iframeRef}
        style={{ width: "100%", height: "100vh" }}
        id="sc-iframe"
        src={`https://efx-dev.stitchcredit.com/api/users/start?key=${API_KEY}&ocl=${LOGO_URL}&oct=${BRAND_COLOR}`}
      ></iframe>

      {/* <button>Test</button> */}
    </>
  );
};

export default CRS;
