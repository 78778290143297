import { useAuth0 } from "@auth0/auth0-react";
import { verticalOrCarousel } from "actions/verticalCarousel";
import AppButton from "common/AppButton";
import AppDropdown from "common/AppDropdown";
import AppTable, { ITableConstants } from "common/AppTable/AppTable";
import * as routes from "constants/routes";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmPaymentAPI, fetchTransactionsAPI } from "services/transactions";
import { RootState } from "store";
import { handleError } from "utils/handleError";
import "./TransactionSection.scss";
import { CardBrandReverse } from "constants/cards";

const initDate = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  },
];
const COMPLETE_PROFILE_INDEX = 4;
// const tFetchTransactionsAPI = throttle(fetchTransactionsAPI, 1000);

const TransactionSection: React.FC<any> = ({
  setDeclineBanner,
  windowWidth,
  setCarousalMode,
  carouselMode,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, getAccessTokenSilently } = useAuth0();
  const userInfo = useSelector((state: RootState) => state.user.user);

  const initialDate = {
    start: new Date(new Date().getTime() - 6 * 30 * 24 * 60 * 60 * 1000).getTime(),
    end: new Date().getTime(),
  };

  const [queryFilter, setQueryFilter] = useState<any>(initialDate);
  const [limit, setLimit] = useState(10);
  const [queryLabel, setQueryLabel] = useState(window.innerWidth < 500 ? "Per Card" : "All Cards");
  const [dateRangeModal, setDateRangeModal] = useState(false);
  const [dateState, setDateState] = useState(initDate);
  const cards = useSelector((state: RootState) => state.cards.cards);
  // const filteredView = useSelector((state: RootState) => state.cardCarousel.filteredView);
  const cardLen = cards.length;

  const cardCarousel = useSelector((state: RootState) => state.cardCarousel);

  const { selectedCardId, selectedCyberSourceId } = cardCarousel;

  const cardId = carouselMode ? selectedCardId || cards?.[0]?.id : undefined;

  console.log("cardId", cardId);
  // filteredCardList filters different cardId according to carousal
  const filteredCardList = cards.filter((card) => card.id === cardId).map((card) => card.cardId);

  console.log(filteredCardList[0]);

  const { data: tablePaginated } = useQuery(
    ["transactions", user, limit, dateState, filteredCardList],
    async () => {
      const token = await getAccessTokenSilently();

      if (!user) return;
      if (!user?.sub) return;

      const response = await fetchTransactionsAPI(
        filteredCardList[0],
        {
          limit,
          startDate: dateState[0]?.startDate,
          endDate: dateState[0]?.endDate,
        },
        {
          startDate: moment(dateState[0]?.startDate).utc().format("YYYY-MM-DD HH:MM"),
          endDate: moment(dateState[0]?.endDate).utc().add(1, "day").format("YYYY-MM-DD HH:MM"),
        },
        user?.sub,
        token,
      );
      return response?.data?.data as Partial<{
        currentPage: number;
        lastPage: number;
        nextPage: number | null;
        prevPage: number | null;
        rows: any[];
        rowsCount: number;
        totalCount: number;
      }>;
    },
  );

  const tableData = !tablePaginated?.rows ? [] : tablePaginated?.rows;
  let pay_amount;

  const activityTableColumns: Array<ITableConstants> = [
    {
      title: "",
      name: "date",
      render(rowData: any) {
        if (!rowData?.createdAt) return <></>;
        const date = moment(rowData.createdAt).format("MM/DD/YYYY");
        return <span>{date}</span>;
      },
    },
    {
      title: "",
      name: "brand",
      render(rowData: any) {
        return (
          <span>
            {" "}
            {`${CardBrandReverse?.[rowData?.card?.brand || ""]} ****${rowData?.card?.lastFour}`}
          </span>
        );
      },
    },
    {
      title: "",
      name: "transaction_type",
      // eslint-disable-next-line react/display-name
      render: (rowData: any) => {
        return rowData && <span> {rowData?.description}</span>;
      },
    },

    {
      title: "",
      name: "state",
      // eslint-disable-next-line react/display-name
      render: (rowData: any) => {
        const txnState = rowData?.transactionState;
        return (
          <span className={`${txnState.toLowerCase().includes("failed") ? "failure" : "success"}`}>
            {txnState}
          </span>
        );
      },
    },

    {
      title: "",
      name: "amount",
      // eslint-disable-next-line react/display-name
      render: (rowData: any) => {
        return rowData && <span>${rowData?.amount}</span>;
      },
    },
    {
      title: "",
      name: "remarks",
      render: (rowData: any) => {
        return <span>{rowData?.remarks}</span>;
      },
    },
  ];

  const date = useMemo(() => {
    return new Date();
  }, []);

  const dateOptions = [
    {
      value: "AllCards",
      label: "All Cards",
      onClick: () => {
        dispatch(verticalOrCarousel());

        setQueryLabel("All Cards");
        setCarousalMode(false);

        //reset all filters
        setLimit(10);
        setQueryFilter(initialDate);
        setDateState(initDate);
      },
    },
    {
      value: "verticalDisplay",
      label: "Per Card",
      onClick: () => {
        setQueryLabel("Per Card");
        setCarousalMode(true);
      },
    },
    {
      value: "custom",
      label:
        dateState[0].startDate?.toDateString() !== date.toDateString() ||
        dateState[0].endDate?.toDateString() !== date.toDateString()
          ? `${dateState[0].startDate?.toLocaleDateString()} - ${dateState[0]?.endDate?.toLocaleDateString()}`
          : "Custom Range",

      onClick: () => {
        setQueryLabel("Custom");
        setQueryFilter("Custom");
        setDateRangeModal(true);
      },
    },
    {
      value: "AllCards",
      label: "Credit Score",
      onClick: () => {
        history.push({
          pathname: "/crs",
        });
      },
    },
  ];

  useEffect(() => {
    if (queryLabel === "All Cards") {
      setQueryLabel("All Cards");
    }
  }, [queryLabel]);

  useEffect(() => {
    if (carouselMode) {
      setQueryLabel("Per Card");
    } else {
      setQueryLabel("All Cards");
    }
  }, [carouselMode]);

  useEffect(() => {
    // if (queryLabel === 'custom') {
    if (
      dateState[0].startDate?.toDateString() !== date.toDateString() ||
      dateState[0].endDate?.toDateString() !== date.toDateString()
    ) {
      setQueryLabel(
        `${dateState[0].startDate?.toLocaleDateString()} - ${dateState[0]?.endDate?.toLocaleDateString()}`,
      );
    }
    //}
  }, [dateState, date]);

  useEffect(() => {
    if (queryFilter === "custom") {
      setDateRangeModal(true);
    }
  }, [queryFilter]);

  const handleConfirmPayment = async (id: any) => {
    const token = await getAccessTokenSilently();
    try {
      if (user && user.sub) {
        let response = await confirmPaymentAPI(user.sub, id, token);
      }
    } catch (error: any) {
      handleError(error?.response?.data?.message);
    }
  };

  const renderDateRangeModal = () => {
    return (
      <Modal show={dateRangeModal} onHide={() => setDateRangeModal(false)} animation={false}>
        <Modal.Body style={{ padding: "12px 8px", alignSelf: "center" }}>
          <DateRange
            editableDateInputs={true}
            onChange={(item) => {
              setDateState([item.selection as any]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={dateState}
          />
        </Modal.Body>
        <AppButton
          className="primary"
          onClick={() => setDateRangeModal(false)}
          style={{ borderRadius: "0" }}
        >
          Apply
        </AppButton>
      </Modal>
    );
  };

  const diff = 10;
  const showMore = tablePaginated?.totalCount && tablePaginated.totalCount >= limit;

  return (
    <div className="activity-section">
      {renderDateRangeModal()}
      <div className="heading">
        <h5>Activity</h5>

        {cardLen > 0 ? null : (
          <AppButton
            className="appButton primary outlined "
            onClick={() => history.push(routes.ONBOARDING1)}
          >
            Complete account setup
          </AppButton>
        )}

        <AppDropdown
          placeholder={queryLabel}
          menuStyle={{ margin: "0.5rem -0.5rem" }}
          style={{ width: "fit-content", padding: "4px" }}
        >
          {dateOptions.map((opt, index) => {
            if (opt.value === "verticalDisplay" && windowWidth >= 500) {
              return;
            }

            return (
              <a
                className={queryLabel === opt.label ? "dropdown-item active" : "dropdown-item"}
                key={`${JSON.stringify(opt)}_${index}`}
                onClick={opt.onClick}
                style={{ height: "2rem" }}
              >
                {opt.label}
              </a>
            );
          })}
        </AppDropdown>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <AppTable
          className="table"
          columns={activityTableColumns}
          data={tableData}
          isLoading={false}
        />
      </div>
      {showMore ? (
        <AppButton
          className="secondary borderless"
          onClick={() => {
            if (showMore) setLimit((page) => page + diff);
            else {
              setLimit(10);
            }
          }}
        >
          {showMore ? `Show More` : `Show Less`}
        </AppButton>
      ) : null}
    </div>
  );
};

export default React.memo(TransactionSection);
