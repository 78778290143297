import { useAuth0 } from "@auth0/auth0-react";
import { Icon } from "@iconify/react";
import { postCard, removeCard, updateCard } from "actions";
import Frame1 from "assets/img/Frame 1.png";
import master_logo from "assets/img/master-logo.png";
import visa_logo from "assets/img/visa-logo.png";
import AppButton from "common/AppButton";
import AppSelect from "common/AppSelect";
import { CardBrand, CardBrandReverse } from "constants/cards";
import { useFormik } from "formik";
import useAdministrativeAreas from "hooks/useAdministrativeAreas";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { ICard } from "types";
import getCardBrand from "utils/cardType";
import { usePostalCodeState } from "utils/zipCode";
import * as Yup from "yup";
import "./cardInput.scss";
import { useFetchUserCtx } from "hooks/useFetchUser";
interface ICardInputProps {
  editMode: boolean;
  cardValue?: ICard;
  isDefault?: boolean;
  onClose?: () => void;
}

const CardInput: React.FC<any> = ({
  editMode = false,
  cardValue,
  isDefault = false,
  onClose,
}: ICardInputProps) => {
  const { user, getAccessTokenSilently } = useAuth0();

  const refetchUser = useFetchUserCtx();

  const [confirmRemove, setConfirmRemove] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.cards.loading);
  const userInfo = useSelector((state: RootState) => state.user.user);
  let first_name = userInfo?.firstName;
  const [flipped, setFlipped] = useState(false);

  const [useSameInfo, setUseSameInfo] = useState(false);
  const [states, stateMap] = useAdministrativeAreas();

  const formikInitialValues = editMode
    ? {
        number: `************${cardValue?.lastFour}`,
        // expirationDate: `${cardValue?.expirationYear.toString()}-${cardValue?.expirationMonth
        //   .toString()
        //   .padStart(2, '0')}`,
        //expirationDate: new Date(`${cardValue?.expirationYear}-${cardValue?.expirationMonth}-01`),
        expirationDate: `${cardValue?.expirationMonth
          .toString()
          .padStart(2, "0")}/${cardValue?.expirationYear?.toString().slice(2)}`,
        cvc: "***",
        brand: "",
        address: {
          address1: cardValue?.address?.address1 || "",
          address2: cardValue?.address?.address2 || "",
          locality: cardValue?.address?.locality || "",
          administrativeArea: cardValue?.address?.administrativeArea || 1,
          country: cardValue?.address?.country || "US",
          postalCode: cardValue?.address?.postalCode || "",
        },
      }
    : {
        number: "",
        expirationDate: "",
        cvc: "",
        brand: "",
        address: {
          address1: "",
          address2: "",
          locality: "",
          administrativeArea: states?.[0]?.value,
          country: "US",
          postalCode: "",
        },
      };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: Yup.object({
      number: Yup.string().required("Card Number is required"),
      expirationDate: Yup.string()
        .matches(/^(0[1-9]|1[0-2])\/[0-9]{2}$/, "Invalid date format")
        .test("validity", "Invalid Date", (value: any) => {
          if (!value) {
            return false;
          }
          const month = parseInt(value.slice(0, 2), 10);
          const year = parseInt(value.slice(-2), 10) + 2000;
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          if (year < currentYear || (year === currentYear && month < currentMonth)) {
            return false; // expiration date is in the past
          }
          if (month < 1 || month > 12) {
            return false; // invalid month number
          }
          return true;
        })
        .required("Expiry Date is required"),
      cvc: Yup.string().required("CVC is required"),
      address: Yup.object().shape({
        address1: Yup.string().required("Street 1 is required"),
        address2: Yup.string().nullable(true),
        locality: Yup.string().required("City is required"),
        administrativeArea: Yup.string(),
        country: Yup.string().nullable(true),
        postalCode: Yup.string()
          .matches(/^\d{5}([-]\d{4})?$/, "Invalid postal code")
          .required("Postal Code is required"),
      }),
    }),
    onSubmit: (values: any) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (useSameInfo) {
      formik.setFieldValue("address.address1", userInfo?.address?.address1);
      formik.setFieldValue("address.address2", userInfo?.address?.address2);
      formik.setFieldValue("address.locality", userInfo?.address?.locality);
      formik.setFieldValue("address.administrativeArea", userInfo?.address?.administrativeArea);
      formik.setFieldValue("address.country", userInfo?.address?.country);
      formik.setFieldValue("address.postalCode", userInfo?.address?.postalCode);
    }
    // } else {
    //   formik.setFieldValue("address.line1", userInfo?.address?.line1);
    //   formik.setFieldValue("address.line2", userInfo?.address?.line2);
    //   formik.setFieldValue("address.city", userInfo?.address?.city);
    //   formik.setFieldValue("address.state", "");
    //   formik.setFieldValue("address.country", "US");
    //   formik.setFieldValue("address.postalCode", userInfo?.address?.postalCode);
    //   formik.setFieldValue("phone", "");
    // }
  }, [useSameInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values: any) => {
    let token = await getAccessTokenSilently();
    // let state = Number(values.address.state);
    // let expYear = values?.expirationDate?.getFullYear();
    // let expMonth = values?.expirationDate?.getMonth() + 1;
    let expYear = `20${values?.expirationDate?.split("/")[1]}`;
    let expMonth = values?.expirationDate?.split("/")[0];

    const requestBody: ICard = {
      number: values.number.replace(/\s/g, ""),
      expirationMonth: parseInt(expMonth, 10),
      expirationYear: parseInt(expYear, 10),
      cvc: parseInt(values.cvc as string, 10),
      brand: CardBrand?.[getCardBrand(values.number)],
      isDefault,
      address: { ...values.address, administrativeArea: values.address.administrativeArea },
    };

    const updateRequestBody: any = {
      cardId: cardValue?.id,
      expirationYear: parseInt(expYear, 10),
      expirationMonth: parseInt(expMonth, 10),
      brand: cardValue?.brand,
      address: { ...values.address },
    };

    //console.log(requestBody);
    //alert(JSON.stringify(requestBody, null, 2));
    if (user?.sub) {
      if (editMode) dispatch(updateCard(user?.sub, updateRequestBody, token));
      else dispatch(postCard(user?.sub, requestBody, token));
    }
  };

  const handleRemoveCard = async () => {
    const token = await getAccessTokenSilently();
    if (user?.sub && cardValue?.id) dispatch(removeCard(user?.sub, cardValue?.id, token));
    refetchUser();
    setConfirmRemove(false);
  };

  const [cardBrand, setCardBrand] = useState<any>(
    cardValue
      ? CardBrandReverse?.[cardValue?.brand || ""] === "mastercard"
        ? master_logo
        : visa_logo
      : Frame1,
  );

  const {
    handleAdministrativeAreaChange: handleStateChange,
    handlePostalCodeChange,
  } = usePostalCodeState(formik);

  return (
    <React.Fragment>
      {/* <ToastContainer /> */}
      <div className={!flipped ? `card-input` : `card-input-flipped`}>
        {!flipped ? (
          <React.Fragment>
            <div className="card-input-header">
              <span style={{ fontSize: "24px" }}>{first_name}</span>
              <img
                src={cardBrand}
                alt="Visa"
                style={{ height: cardBrand === master_logo ? "48px" : "24px" }}
              />
            </div>
            <div className="card-input-body">
              <input
                id="ccn"
                type="tel"
                name="number"
                inputMode="numeric"
                pattern="[0-9\s]{13,19}"
                autoComplete="cc-number"
                maxLength={19}
                placeholder="---- ---- ---- ----"
                value={formik.values.number}
                onChange={(e) => {
                  let value = e.target.value;
                  value = value
                    .replace(/[^\dA-Z]/g, "")
                    .replace(/(.{4})/g, "$1 ")
                    .trim();
                  e.target.value = value;
                  formik.handleChange(e);
                }}
                onBlur={(e: any) => {
                  formik.handleBlur(e);

                  const value = e.target.value;
                  const brand = getCardBrand(value);

                  if (brand?.toLowerCase() === "visa") setCardBrand(visa_logo);
                  else if (brand?.toLowerCase() === "mastercard") setCardBrand(master_logo);
                  else setCardBrand(Frame1);
                }}
                disabled={editMode}
                // onKeyDown={(e: any) => {
                //   // formik.setFieldValue(
                //   //   "number",
                //   //   `${e.target.value
                //   //     .replace(/[^\dA-Z]/g, "")
                //   //     .replace(/(.{4})/g, "$1 ")
                //   //     .trim()}`,
                //   // );
              />
              {formik.errors.number && formik.touched.number && (
                <div className="invalid-feedback-text">{formik.errors.number}</div>
              )}
            </div>
            <div className="card-input-footer">
              <div className="form-group d-flex flex-column">
                <label htmlFor="valid">Valid Until</label>
                <input
                  id="valid"
                  type="text"
                  name="expirationDate"
                  placeholder="MM/YY"
                  value={formik.values.expirationDate}
                  maxLength={5}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^0-9/]/g, "");
                    e.target.value = value;
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e: any) => {
                    let value = e.target.value;

                    if (e.key !== "Backspace" && e.key !== "/") {
                      if (value.length === 2) {
                        formik.setFieldValue(
                          "expirationDate",
                          `${value.replace(/(.{2})/g, "$1/").trim()}`,
                        );
                      }
                    }
                  }}
                />
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="yyyy-MM"
                    views={['year', 'month']}
                    // label="Year and Month"
                    minDate={new Date()}
                    value={formik.values.expirationDate}
                    onChange={(value: any) => {
                      formik.setFieldValue('expirationDate', value);
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} helperText={null} />
                    )}
                  />
                </LocalizationProvider> */}

                {formik.errors.expirationDate && formik.touched.expirationDate && (
                  <div className="invalid-feedback-text">{formik.errors.expirationDate}</div>
                )}
              </div>
              <div className="form-group d-flex flex-column">
                <label htmlFor="valid" style={{ textAlign: "right" }}>
                  CVC
                </label>
                <input
                  id="cvc"
                  name="cvc"
                  type="tel"
                  inputMode="numeric"
                  maxLength={4}
                  placeholder="_ _ _ _"
                  value={formik.values.cvc}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^0-9/]/g, "");
                    e.target.value = value;
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  disabled={editMode}
                />
                {formik.errors.cvc && formik.touched.cvc && (
                  <div
                    className="invalid-feedback-text"
                    style={{ textAlign: "right", whiteSpace: "nowrap" }}
                  >
                    {formik.errors.cvc}
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="card-input-header" onClick={() => setFlipped(false)}>
              <span>Billing Address</span>
              {/* <img src={visa_logo} alt="Visa" /> */}
            </div>
            {userInfo?.address && (
              <div className="form-row align-items-center">
                <Form.Check
                  id="sameInfo"
                  checked={useSameInfo}
                  onChange={() => setUseSameInfo((prev) => !prev)}
                  style={{ width: "fit-content" }}
                />
                <Form.Check.Label>Use same as profile address</Form.Check.Label>
              </div>
            )}
            <div className="card-input-body-flipped">
              <Form.Group as={Row}>
                <Col xs={6}>
                  <input
                    id="line1"
                    name="address.address1"
                    type="text"
                    placeholder="Street 1"
                    value={formik.values.address?.address1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.address?.address1 && formik.touched.address?.address1 && (
                    <div className="invalid-feedback-text">{formik.errors.address?.address1}</div>
                  )}
                </Col>
                <Col xs={6}>
                  <input
                    id="line2"
                    name="address.address2"
                    type="text"
                    placeholder="Street 2"
                    value={formik.values.address?.address2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col>
                  <input
                    id="city"
                    name="address.locality"
                    type="text"
                    placeholder="City"
                    value={formik.values.address?.locality}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.address?.locality && formik.touched.address?.locality && (
                    <div className="invalid-feedback-text">{formik.errors.address?.locality}</div>
                  )}
                </Col>
                <Col>
                  <AppSelect
                    options={states}
                    size="sm"
                    style={{ WebkitAppearance: "menulist", maxWidth: "100%" }}
                    name="address.administrativeArea"
                    value={formik.values?.address?.administrativeArea}
                    onChange={handleStateChange}
                    onBlur={formik.handleBlur}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col xs={6}>
                  <input
                    id="country"
                    name="address.country"
                    type="text"
                    value={formik.values.address?.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={true}
                  />
                </Col>
                <Col xs={6}>
                  <input
                    id="zip"
                    placeholder="Zip Code"
                    name="address.postalCode"
                    type="text"
                    maxLength={5}
                    autoComplete="off"
                    value={formik.values.address?.postalCode}
                    onChange={handlePostalCodeChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.address?.postalCode && formik.touched.address?.postalCode && (
                    <div className="invalid-feedback-text">{formik.errors.address?.postalCode}</div>
                  )}
                  {formik.errors.address?.administrativeArea &&
                    formik.touched.address?.administrativeArea && (
                      <div className="invalid-feedback-text">
                        {formik.errors.address?.administrativeArea}
                      </div>
                    )}
                </Col>
              </Form.Group>
            </div>
          </React.Fragment>
        )}
      </div>
      {editMode ? (
        confirmRemove ? (
          <React.Fragment>
            <p>Are you sure you want to remove this card?</p>
            <div className="btn-container">
              <AppButton
                size="sm"
                className="primary outlined "
                onClick={() => setConfirmRemove(false)}
              >
                Cancel
              </AppButton>
              <AppButton
                size="sm"
                className="tertiary"
                onClick={() => handleRemoveCard()}
                isLoading={loading}
                disabled={loading}
              >
                Confirm
              </AppButton>
            </div>
          </React.Fragment>
        ) : flipped ? (
          <React.Fragment>
            <AppButton
              size="lg"
              type="submit"
              className="primary w-100 mb-2"
              onClick={formik.handleSubmit}
              isLoading={loading}
              disabled={loading}
            >
              Save Changes
            </AppButton>
            <AppButton
              size="lg"
              className="borderless primary w-100"
              onClick={() => setConfirmRemove(true)}
              id="remove-btn"
            >
              Remove Card
            </AppButton>
          </React.Fragment>
        ) : (
          <>
            <AppButton
              size="sm"
              className="borderless primary"
              style={{ alignSelf: "end", padding: "0", width: "20%" }}
              onClick={() => {
                formik.validateForm().then((errors) => {
                  if (
                    isEmpty(errors.expirationDate) &&
                    isEmpty(errors.number) &&
                    isEmpty(errors.cvc)
                  ) {
                    setFlipped(true);
                  } else {
                    formik.setTouched({ number: true, expirationDate: true, cvc: true });
                  }
                });
              }}
            >
              Next
              <Icon icon="uil:arrow-right" width="30px" style={{ margin: 2 }} />
            </AppButton>
            <AppButton
              size="lg"
              className="borderless primary w-100"
              onClick={() => setConfirmRemove(true)}
              id="remove-btn"
            >
              Remove Card
            </AppButton>
          </>
        )
      ) : flipped ? (
        <>
          <AppButton
            size="sm"
            className="borderless primary"
            style={{ float: "left", padding: "0", width: "100%", textAlign: "start" }}
            onClick={() => setFlipped(false)}
          >
            <Icon icon="uil:arrow-left" width="30px" style={{ margin: 2 }} />
            Previous
          </AppButton>
          <AppButton
            size="lg"
            type="submit"
            className="primary w-100"
            onClick={formik.handleSubmit}
            isLoading={loading}
            disabled={loading}
          >
            Add Card
          </AppButton>
        </>
      ) : (
        <AppButton
          size="sm"
          className="borderless primary"
          style={{ alignSelf: "end", padding: "0", width: "20%" }}
          onClick={() => {
            formik.validateForm().then((errors) => {
              if (isEmpty(errors.expirationDate) && isEmpty(errors.number) && isEmpty(errors.cvc)) {
                setFlipped(true);
              } else {
                formik.setTouched({ number: true, expirationDate: true, cvc: true });
              }
            });
          }}
        >
          Next
          <Icon icon="uil:arrow-right" width="30px" style={{ margin: 2 }} />
        </AppButton>
      )}
    </React.Fragment>
  );
};

export default CardInput;
