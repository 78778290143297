import VisibilityIcon from "@mui/icons-material/Visibility";
import { getAllCustomers } from "../../../api/admin";
import DynamicTable from "components/DynamicTable";
import { useFilter } from "components/DynamicTable/FilterOptions";
import { format } from "date-fns";
import useGetAccessToken from "hooks/useGetAccessToken";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import * as routes from "constants/routes";

export type CustomerDataType = {
  // sn: number;
  id: string;
  email: string;
  createdAt: string;
  firstName?: string;
  fullName?: string;
  address?: string;
  phone?: string | number;
  view?: any;
};

export const formatDate = (date: string) => {
  const dateObj = new Date(date);
  return format(dateObj, "yyyy-MM-dd");
};

const UsersAdmin = () => {
  const { filterOptions } = useFilter({
    limit: 10,
    order: "desc",
  });

  const history = useHistory();
  const token = useGetAccessToken();
  const { isLoading, data: allCustomerData } = useQuery(
    ["allCustomer", filterOptions, token],
    () => token && getAllCustomers(token, filterOptions),
  );

  const tableData = useMemo<CustomerDataType[]>(() => {
    try {
      return allCustomerData?.data?.rows?.map((clientData: any, index: number) => {
        let addressArray = [clientData?.address?.locality, clientData?.address?.country];
        const address = addressArray.filter((x) => Boolean(x)).join(",");

        return {
          sn:
            (allCustomerData?.data?.currentPage - 1) * allCustomerData?.data?.rowsCount + index + 1,
          id: clientData?.id,
          createdAt: formatDate(clientData?.createdAt),
          email: clientData?.email,
          address,
          phone: clientData?.phone,

          // firstName is a variable which stores fullname, naming it as firstName it for sorting purposes,
          firstName: `${clientData?.firstName}` + " " + `${clientData?.lastName}`,
          view: (
            <VisibilityIcon
              onClick={() => {
                  history.push({
                    pathname: `${routes.ADMIN_USER_INFO}`,
                    state: { userData: clientData },
                  });
              }}
            />
          ),
        };
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }, [allCustomerData, history]);

  return (
    <DynamicTable<CustomerDataType>
      title="Client Details"
      td={tableData}
      th={[
        // { accessor: "sn", h: "S.N", isSortable: false },
        { accessor: "firstName", h: "Name", isSortable: true },
        { accessor: "email", h: "Email", isSortable: true },
        { accessor: "createdAt", h: "Created At", isSortable: true },
        { accessor: "address", h: "Address", isSortable: true },
        { accessor: "phone", h: "Phone", isSortable: true },
        { accessor: "view", h: "Details", isSortable: false },
      ]}
      // filterList={filterList}
      totalPages={allCustomerData?.data?.lastPage}
      totalResults={allCustomerData?.data?.totalCount}
      isLoading={isLoading}
      showMore={""}
    />
  );
};

export default UsersAdmin;
